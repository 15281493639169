import graphql from "babel-plugin-relay/macro";
import { setToggle } from "common/utils/universal/set";
import { IncludedTemplateFieldsType } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import { networkOptionsDhcpEnabledFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsDhcpEnabledField";
import { networkOptionsDnsServerFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsDnsServerField";
import { networkOptionsGatewayAddressFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsGatewayAddressField";
import { networkOptionsIpv6AddressFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsIpv6AddressField";
import { networkOptionsIpv6DnsServerFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsIpv6DnsServerField";
import { networkOptionsIpv6GatewayFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsIpv6GatewayField";
import { networkOptionsIpv6PrefixBitsFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsIpv6PrefixBitsField";
import { networkOptionsListenPort734NFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsListenPort734NField";
import { networkOptionsLocalIpAddressFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsLocalIpAddressField";
import { networkOptionsPassphrase734NFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsPassphrase734NField";
import { networkOptionsPassphraseFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsPassphraseField";
import { networkOptionsProgrammingPortFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsProgrammingPortField";
import { networkOptionsSubnetMaskFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsSubnetMaskField";
import { networkOptionsUseIpv6FieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsUseIpv6Field";
import { networkOptionsWifiPassphraseFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsWifiPassphraseField";
import { networkOptionsWifiSecurityFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsWifiSecurityField";
import { networkOptionsWifiSsidFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsWifiSsidField";
import { networkOptionsXrX1CommunicationFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsXrX1CommunicationField";
import { networkOptionsXrX1PassphraseFieldId } from "components/FullProgramming/common/NetworkOptionsFields/NetworkOptionsXrX1PassphraseField";
import { XT75NetworkOptionsProgrammingConceptFormInline_controlSystem$key } from "components/FullProgramming/XT75FullProgramming/__generated__/XT75NetworkOptionsProgrammingConceptFormInline_controlSystem.graphql";
import { Dispatch, SetStateAction } from "react";
import { readInlineData } from "react-relay";
import { getState as getNetworkOptionsState } from "../../../XT75FullProgramming/XT75NetworkOptionsProgrammingConceptForm";
import { ProgrammingTemplateNetworkOptionsInput } from "../Create/__generated__/XT75TemplateSaveMutation.graphql";
import { XT75NetworkOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key } from "./__generated__/XT75NetworkOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts.graphql";

export const getState = (
  template: XT75NetworkOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XT75NetworkOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts on Xt75ProgrammingTemplateConcepts
      @inline {
        networkOptions {
          id
          included
          wifiSsid {
            included
            data
          }
          wifiSecurity {
            included
            data
          }
          wifiPassphrase {
            included
            data
          }
          dhcpEnabled {
            included
            data
          }
          localIpAddress {
            included
            data
          }
          gatewayAddress {
            included
            data
          }
          subnetMask {
            included
            data
          }
          dnsServer {
            included
            data
          }
          listenPort734N {
            included
            data
          }
          passphrase734N {
            included
            data
          }
          ipv6Enabled {
            included
            data
          }
          ipv6Address {
            included
            data
          }
          ipv6Gateway {
            included
            data
          }
          ipv6Prefix {
            included
            data
          }
          ipv6Dns {
            included
            data
          }
          programmingPort {
            included
            data
          }
          passphrase {
            included
            data
          }
          enableDdp {
            included
            data
          }
          ddpPassphrase {
            included
            data
          }
        }
      }
    `,
    template
  );

export const getInput = (
  controlSystem: XT75NetworkOptionsProgrammingConceptFormInline_controlSystem$key,
  includedFields: IncludedTemplateFieldsType
): ProgrammingTemplateNetworkOptionsInput => {
  const {
    panel: { networkOptions },
  } = getNetworkOptionsState(controlSystem);

  if (!networkOptions) {
    return { included: false };
  }

  const networkOptionsInputFields = {
    wifiSsid: {
      included: includedFields.has(networkOptionsWifiSsidFieldId()),
      data: networkOptions.wifiSsid,
    },
    wifiSecurity: {
      included: includedFields.has(networkOptionsWifiSecurityFieldId()),
      data: networkOptions.wifiSecurity,
    },
    wifiPassphrase: {
      included: includedFields.has(networkOptionsWifiPassphraseFieldId()),
      data: networkOptions.wifiPassphrase,
    },
    dhcpEnabled: {
      included: includedFields.has(networkOptionsDhcpEnabledFieldId()),
      data: networkOptions.dhcpEnabled,
    },
    ipv6Enabled: {
      included: includedFields.has(networkOptionsUseIpv6FieldId()),
      data: networkOptions.ipv6Enabled,
    },
    localIpAddress: {
      included: includedFields.has(networkOptionsLocalIpAddressFieldId()),
      data: networkOptions.localIpAddress,
    },
    gatewayAddress: {
      included: includedFields.has(networkOptionsGatewayAddressFieldId()),
      data: networkOptions.gatewayAddress,
    },
    subnetMask: {
      included: includedFields.has(networkOptionsSubnetMaskFieldId()),
      data: networkOptions.subnetMask,
    },
    dnsServer: {
      included: includedFields.has(networkOptionsDnsServerFieldId()),
      data: networkOptions.dnsServer,
    },
    listenPort734N: {
      included: includedFields.has(networkOptionsListenPort734NFieldId()),
      data: networkOptions.listenPort734N,
    },
    passphrase734N: {
      included: includedFields.has(networkOptionsPassphrase734NFieldId()),
      data: networkOptions.passphrase734N,
    },
    ipv6Address: {
      included: includedFields.has(networkOptionsIpv6AddressFieldId()),
      data: networkOptions.ipv6Address,
    },
    ipv6Gateway: {
      included: includedFields.has(networkOptionsIpv6GatewayFieldId()),
      data: networkOptions.ipv6Gateway,
    },
    ipv6Prefix: {
      included: includedFields.has(networkOptionsIpv6PrefixBitsFieldId()),
      data: networkOptions.ipv6Prefix,
    },
    ipv6Dns: {
      included: includedFields.has(networkOptionsIpv6DnsServerFieldId()),
      data: networkOptions.ipv6Dns,
    },
    programmingPort: {
      included: includedFields.has(networkOptionsProgrammingPortFieldId()),
      data: networkOptions.programmingPort,
    },
    passphrase: {
      included: includedFields.has(networkOptionsPassphraseFieldId()),
      data: networkOptions.passphrase,
    },
    enableDdp: {
      included: includedFields.has(networkOptionsXrX1CommunicationFieldId()),
      data: networkOptions.enableDdp,
    },
    ddpPassphrase: {
      included: includedFields.has(networkOptionsXrX1PassphraseFieldId()),
      data: networkOptions.ddpPassphrase,
    },
  };

  const networkOptionsIncluded = Object.values(networkOptionsInputFields).some(
    ({ included }) => included
  );

  return {
    included: networkOptionsIncluded,
    ...networkOptionsInputFields,
  };
};

export const setIncludedFields = (
  template: XT75NetworkOptionsTemplateDataInline_xt75ProgrammingTemplateConcepts$key,
  setIncludedFields: Dispatch<SetStateAction<IncludedTemplateFieldsType>>
) => {
  const { networkOptions } = getState(template);

  if (!networkOptions) return;

  if (networkOptions.wifiSsid?.included) {
    setIncludedFields(setToggle(networkOptionsWifiSsidFieldId()));
  }
  if (networkOptions.wifiSecurity?.included) {
    setIncludedFields(setToggle(networkOptionsWifiSecurityFieldId()));
  }
  if (networkOptions.wifiPassphrase?.included) {
    setIncludedFields(setToggle(networkOptionsWifiPassphraseFieldId()));
  }
  if (networkOptions.dhcpEnabled?.included) {
    setIncludedFields(setToggle(networkOptionsDhcpEnabledFieldId()));
  }
  if (networkOptions.localIpAddress?.included) {
    setIncludedFields(setToggle(networkOptionsLocalIpAddressFieldId()));
  }
  if (networkOptions.gatewayAddress?.included) {
    setIncludedFields(setToggle(networkOptionsGatewayAddressFieldId()));
  }
  if (networkOptions.subnetMask?.included) {
    setIncludedFields(setToggle(networkOptionsSubnetMaskFieldId()));
  }
  if (networkOptions.dnsServer?.included) {
    setIncludedFields(setToggle(networkOptionsDnsServerFieldId()));
  }
  if (networkOptions.listenPort734N?.included) {
    setIncludedFields(setToggle(networkOptionsListenPort734NFieldId()));
  }
  if (networkOptions.passphrase734N?.included) {
    setIncludedFields(setToggle(networkOptionsPassphrase734NFieldId()));
  }
  if (networkOptions.ipv6Enabled?.included) {
    setIncludedFields(setToggle(networkOptionsUseIpv6FieldId()));
  }
  if (networkOptions.ipv6Address?.included) {
    setIncludedFields(setToggle(networkOptionsIpv6AddressFieldId()));
  }
  if (networkOptions.ipv6Gateway?.included) {
    setIncludedFields(setToggle(networkOptionsIpv6GatewayFieldId()));
  }
  if (networkOptions.ipv6Prefix?.included) {
    setIncludedFields(setToggle(networkOptionsIpv6PrefixBitsFieldId()));
  }
  if (networkOptions.ipv6Dns?.included) {
    setIncludedFields(setToggle(networkOptionsIpv6DnsServerFieldId()));
  }
  if (networkOptions.programmingPort?.included) {
    setIncludedFields(setToggle(networkOptionsProgrammingPortFieldId()));
  }
  if (networkOptions.passphrase?.included) {
    setIncludedFields(setToggle(networkOptionsPassphraseFieldId()));
  }
  if (networkOptions.enableDdp?.included) {
    setIncludedFields(setToggle(networkOptionsXrX1CommunicationFieldId()));
  }
  if (networkOptions.ddpPassphrase?.included) {
    setIncludedFields(setToggle(networkOptionsXrX1PassphraseFieldId()));
  }
};
