import { between, getHardwareFamilyFromHardwareModel } from "utils";

/**
 * @ngdoc object
 * @name App.controller:UserEditCtrl
 *
 * @description
 *  Controller for the Editing and Creating new Customer Users.
 */
App.controller("UserEditCtrl", [
  "$rootScope",
  "$scope",
  "$q",
  "$modal",
  "UserService",
  "CustomerUser",
  "Customer",
  "LoginService",
  "customer_id",
  "$filter",
  "$window",
  "$state",
  "$stateParams",
  "ODataService",
  "PersonsService",
  "PersonUsersService",
  "INPUT_PROPERTIES",
  "$timeout",
  "TIMEOUTS",
  "$sanitize",
  function (
    $rootScope,
    $scope,
    $q,
    $modal,
    UserService,
    CustomerUser,
    Customer,
    LoginService,
    customer_id,
    $filter,
    $window,
    $state,
    $stateParams,
    ODataService,
    PersonsService,
    PersonUsersService,
    INPUT_PROPERTIES,
    $timeout,
    TIMEOUTS,
    $sanitize
  ) {
    $scope.loading = false;
    var viewingUser = Boolean($state.is("app.user.viewuser"));
    var editingUser = Boolean(
      $state.is("app.user.edituser") || $state.is("dealer.customer.appuseredit")
    );
    var newUser = !viewingUser && !editingUser;
    $scope.selectAllSystems = false;
    $scope.filter = { systems: "" };
    // Default the form to create a random password for the new user
    $scope.password = {
      random: true,
    };
    $scope.dealerData = UserService.dealerInfo;

    // Variables used for managing persons with multiple users
    $scope.canMergeUsers = UserService.canMergeUsers();
    $scope.personUsers = {
      // The current user, the person it belongs to and the other users merged under that person
      currentPerson: {
        // The user being worked on
        selectedUser: null,
        // List of customers for which the person has an app user
        customers: [],
      },
      // Results from searching personUsers
      searchResults: [],
    };
    $scope.userForm = null;
    $scope.debounce_ms = INPUT_PROPERTIES.SHORT_DEBOUNCE_MS;
    $scope.refreshingSearchList = false;
    var createNewEmailToolTip = "Enter a new email address";
    var useExistingEmailToolTip =
      "Type at least 3 characters to search existing users by name or email";
    $scope.emailOptions = {
      toolTip: createNewEmailToolTip,
      createWithExistingAddress: false,
    };

    // We're using this name because we're sharing a view (user-edit.html) between new and edit, so the control alias
    // must be the same for both.
    var newedituser = this; // ControllerAs
    newedituser.customer = new Customer(customer_id);
    UserService.setCustomerInfo(newedituser.customer);
    newedituser.customer_id = customer_id;
    newedituser.dealer_id = UserService.dealer_id;
    newedituser.systemCount = 0;

    const newEditUserIsAdmin = (newEditUser) =>
      newEditUser?.user?.role?.toLowerCase() === "admin";

    function init() {
      $scope.loading = true;
      // Create a new customer user object
      newedituser.user = new CustomerUser();
      if (viewingUser || editingUser) {
        newedituser.user_id = $stateParams.user_id;
      }
      var userRetrieveFunction = newUser
        ? newedituser.user.new(customer_id)
        : newedituser.user.get($stateParams.user_id);
      userRetrieveFunction
        .then(function () {
          // Get the control system only after we've received the user object
          var controlSystems = [];
          ODataService.getControlSystems(UserService.dealer_id, customer_id)
            .then(
              function (systems) {
                angular.forEach(systems, function (system) {
                  if (system.panels[0].hardware_model !== "TMS6") {
                    controlSystems.push(system);
                  }
                });
                prepUserPermissions(controlSystems);
                if (!newedituser.user.isNew) {
                  // Get the person information for the current user
                  PersonUsersService.getByUserId(newedituser.user.id)
                    .then(
                      function (personUser) {
                        $scope.personUsers.currentPerson.selectedUser =
                          personUser;
                        // Get all the users of the same person
                        PersonUsersService.getByPersonId(personUser.person_id)
                          .then(
                            function (personUsers) {
                              angular.forEach(personUsers, function (pUser) {
                                // If the user has a customer id, they are an app user. If it's different from the current user...
                                if (
                                  pUser.customer_id &&
                                  pUser.customer_id !==
                                    newedituser.user.accessible_id
                                ) {
                                  // If the customer information is not already in the list of customers for the person, add it
                                  var customersIdx =
                                    $scope.personUsers.currentPerson.customers.findIndex(
                                      function (c) {
                                        return c.id === pUser.customer_id;
                                      }
                                    );
                                  if (customersIdx === -1) {
                                    $scope.personUsers.currentPerson.customers.push(
                                      {
                                        id: pUser.customer_id,
                                        name: pUser.customer_name,
                                      }
                                    );
                                  }
                                }
                              });
                              $scope.loading = false;
                            },
                            function () {
                              $rootScope.alerts.push({
                                type: "error",
                                text: "error retrieving linked users",
                              });
                              $scope.loading = false;
                            }
                          )
                          .catch(function (error) {
                            console.error(error);
                          });
                      },
                      function () {
                        $rootScope.alerts.push({
                          type: "error",
                          text: "error retrieving existing users",
                        });
                        $scope.loading = false;
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                } else {
                  $scope.loading = false;
                }
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "error getting systems",
                  json: error,
                });
                $scope.loading = false;
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    const selectSiteUserPermissions = (permission) => {
      permission.multi_panel_profiles_enabled = false;
      permission.multi_panel_schedules_enabled = false;
      permission.arming_enabled = false;
      permission.remote_panic_police_enabled = false;
      permission.remote_panic_fire_enabled = false;
      permission.remote_panic_emergency_enabled = false;

      if (permission.panel_access) {
        const isAdmin = newEditUserIsAdmin(newedituser);

        permission.reports_enabled = true;
        permission.view_stored_user_codes = isAdmin;
        permission.multi_panel_user_codes_enabled = isAdmin;
      } else {
        permission.reports_enabled = false;
        permission.view_stored_user_codes = false;
        permission.multi_panel_user_codes_enabled = false;
      }
    };

    /**
     * Update personUsers search results
     * @param {string} searchText - the text to use for search terms
     */
    $scope.refreshUISearchResults = function (searchText) {
      $scope.refreshingSearchList = true;
      PersonUsersService.searchAll(searchText)
        .then(
          function (data) {
            $scope.personUsers.searchResults = filterSearchResults(data);
            $scope.refreshingSearchList = false;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error searching users",
              json: error,
            });
            $scope.refreshingSearchList = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Filter search results for using existing email to create new user and merging users
     * @param pUsers
     * @returns {Array} - an array of PersonUsers
     */
    function filterSearchResults(pUsers) {
      var searchResults = [];
      angular.forEach(pUsers, function (pUser) {
        if (!pUser.user_hidden) {
          searchResults.push(pUser);
        }
      });
      return searchResults;
    }

    /**
     * Attach a form to scope
     * @param form
     */
    $scope.scopeUserForm = function (form) {
      $scope.userForm = form;
    };

    /**
     * UI-selects don't work with required. Watch the email_ui_select property on the form for when the ui-select is
     * ng-if'd in/out and watch the selected user to validate for changes. If email is not valid, make an error
     * on the form so the message can be displayed, causing the save button to be disabled
     */
    $scope.$watchGroup(
      [
        "personUsers.currentPerson.selectedUser.user_id",
        "userForm.email_ui_select.$touched",
      ],
      function (newValues, oldValues) {
        var userSelected = +newValues[0] > 0;
        // Manually set the error
        if (
          $scope.userForm &&
          $scope.userForm.hasOwnProperty("email_ui_select")
        ) {
          if (
            $scope.userForm.email_ui_select.$touched &&
            $scope.emailOptions.createWithExistingAddress &&
            !userSelected
          ) {
            $scope.userForm.email_ui_select.$setValidity("required", false);
          } else {
            $scope.userForm.email_ui_select.$setValidity("required", true);
          }
        }
        var userChanged = userSelected && +newValues[0] !== +oldValues[0];
        if (userChanged) {
          newedituser.user.first_name =
            $scope.personUsers.currentPerson.selectedUser.person_first_name;
          newedituser.user.last_name =
            $scope.personUsers.currentPerson.selectedUser.person_last_name;
        }
      }
    );

    $scope.resetAppUserPassword = ({ person_id, email, hidden }) => {
      // SSO users' emails come in as a GUID so we need to grab the person's email instead if the user is single sign on (user.hidden: true)
      PersonsService.resetAccess(
        person_id,
        hidden
          ? $scope.personUsers.currentPerson.selectedUser.person_email_address
          : email
      )
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Password reset for " + $sanitize(email),
            });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error resetting password",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Use Existing Email button clicked function
     */
    $scope.useExistingButtonClicked = function () {
      $scope.emailOptions.toolTip = $scope.emailOptions
        .createWithExistingAddress
        ? useExistingEmailToolTip
        : createNewEmailToolTip;
      resetUserForm();
    };

    /**
     * Reset the form to clear has-error state on all inputs
     */
    function resetUserForm() {
      $scope.userForm.$setUntouched();
      $scope.userForm.$setPristine();
      $scope.personUsers.currentPerson.selectedUser = null;
    }

    $scope.noSystemNameX1Filter = function (permission) {
      return angular.isDefined(permission.system_name);
    };

    const isX1 = (permission) => permission.hardware_model === "X001";
    const isXF = (permission) =>
      ["XF6_100", "XF6_500"].includes(permission.hardware_model);

    /**
     * Because of the way user_permissions is formatted from the API, we will rearrange it to make more sense from
     * a UI coding perspective.  This means creating dummy user_permissions objects for ones that don't currently
     * exist, as well as adding system_name to the ones that do exist.
     */
    function prepUserPermissions(systems) {
      //Sets panel_access(DA local variable) to true for all existing permissions since if they exist here they already have access
      newedituser.user.user_permissions.map(
        (permission) => (permission.panel_access = true)
      );
      angular.forEach(systems, function (system) {
        // If there is a corresponding user_permission for this panel, add a system_name.
        var permission = newedituser.user.user_permissions.find(function (p) {
          return p.panel_id === system.panels[0].id;
        });
        if (angular.isDefined(permission)) {
          permission.system_name = system.name;
          permission.hardware_model = system.panels[0].hardware_model;
          permission.software_version = system.panels[0].software_version;
          permission.site_id = system.site_id;
        } else {
          // Create one, to be the model for potential checkboxes
          newedituser.user.user_permissions.push({
            panel_id: system.panels[0].id,
            hardware_model: system.panels[0].hardware_model,
            system_name: system.name,
            software_version: system.panels[0].software_version,
            site_id: system.site_id,
            reports_enabled: false,
            multi_panel_user_codes_enabled: false,
            view_stored_user_codes: false,
            multi_panel_schedules_enabled: false,
            arming_enabled: false,
            remote_panic_police_enabled: false,
            remote_panic_fire_enabled: false,
            remote_panic_emergency_enabled: false,
          });
        }
      });
    }

    newedituser.selectAllPanels = function () {
      for (var i = 0; i < newedituser.user.user_permissions.length; i++) {
        var system = newedituser.user.user_permissions[i];
        system.panel_access = true;
        if (system.site_id) {
          selectSiteUserPermissions(system);
        }
      }
    };

    newedituser.deselectAllPanels = function () {
      for (var i = 0; i < newedituser.user.user_permissions.length; i++) {
        var system = newedituser.user.user_permissions[i];
        system.panel_access = false;
        system.reports_enabled = false;
        system.view_stored_user_codes = false;
        system.multi_panel_user_codes_enabled = false;
        system.multi_panel_schedules_enabled = false;
        system.arming_enabled = false;
        system.remote_panic_police_enabled = false;
        system.remote_panic_fire_enabled = false;
        system.remote_panic_emergency_enabled = false;
        system.multi_panel_profiles_enabled = false;
      }
    };

    newedituser.setFieldValue = function (field, enable) {
      for (var i = 0; i < newedituser.user.user_permissions.length; i++) {
        var permission = newedituser.user.user_permissions[i];

        if (
          permission.panel_access === true &&
          permission.hardware_model !== "Video Only" &&
          !isX1(permission)
        ) {
          if (
            (field === "remote_panic_police_enabled" ||
              field === "remote_panic_fire_enabled" ||
              field === "remote_panic_emergency_enabled") &&
            (!$scope.panelVersionSupportsRemotePanic(
              permission.software_version
            ) ||
              isXF(permission))
          ) {
            permission[field] = false;
          } else if (
            field === "multi_panel_profiles_enabled" &&
            (!$scope.panelSupportsAdminProfiles(permission) ||
              !$scope.panelVersionSupportsAdminUsers(
                permission.software_version
              ))
          ) {
            permission[field] = false;
          } else if (
            field === "multi_panel_schedules_enabled" &&
            !$scope.panelSupportsAdminSchedules(permission)
          ) {
            permission[field] = false;
          } else if (
            field === "arming_enabled" &&
            !$scope.panelSupportsArming(permission)
          ) {
            permission[field] = false;
          } else {
            permission[field] = enable;
          }
        }
      }
    };

    $scope.panelVersionSupportsRemotePanic = function (panelVersion) {
      return (panelVersion > 193 && panelVersion < 600) || panelVersion > 692;
    };

    $scope.panelVersionSupportsAdminUsers = function (panelVersion) {
      return (
        between(panelVersion, 191, 599) || between(panelVersion, 691, Infinity)
      );
    };

    $scope.panelSupportsAdminProfiles = ({
      hardware_model,
      software_version,
    }) =>
      getHardwareFamilyFromHardwareModel(hardware_model) === "XR550" &&
      (between(software_version, 191, 599) ||
        between(software_version, 691, Infinity));

    $scope.panelSupportsAdminSchedules = (permission) =>
      permission.hardware_model !== "CellComEX" && !isXF(permission);
    $scope.panelSupportsArming = (permission) => !isXF(permission);

    $scope.systemClicked = function (permission) {
      if (permission.panel_access === false) {
        permission.reports_enabled = false;
        permission.view_stored_user_codes = false;
        permission.multi_panel_schedules_enabled = false;
        permission.multi_panel_user_codes_enabled = false;
        permission.arming_enabled = false;
        permission.remote_panic_police_enabled = false;
        permission.remote_panic_fire_enabled = false;
        permission.remote_panic_emergency_enabled = false;
        permission.multi_panel_profiles_enabled = false;
      } else if (permission.site_id) {
        selectSiteUserPermissions(permission);
      }
    };

    $scope.saveAppUserDA = function () {
      $scope.loading = true;
      newedituser
        .saveAppUser()
        .then(
          function () {
            /**
             * Delay before navigating back to the list
             * note: Production tables are load balanced. This allows the database to catch up so that the new item is
             *       not missing from the list.
             */
            $timeout(function () {
              $scope.loading = false;
              $state.go("app.customers.customersummary", {
                customer_id: customer_id,
                dealer_id: newedituser.dealer_id,
              });
            }, TIMEOUTS.REDUNDANCY);
          },
          function (error) {
            console.error(
              "UserEditCtrl->saveAppUserDA() error: " + angular.toJson(error)
            );
            $scope.loading = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Save the app user: update the person, user and/or merge in any users to be merged
     * @returns {Promise}
     */
    newedituser.saveAppUser = function () {
      var deferred = $q.defer();
      // Create/update person/user/merge users in order based on some properties
      if (newedituser.user.isNew) {
        newedituser.user.email =
          $scope.personUsers.currentPerson.selectedUser.person_email_address;
        newedituser.user.first_name =
          $scope.personUsers.currentPerson.selectedUser.person_first_name;
        newedituser.user.last_name =
          $scope.personUsers.currentPerson.selectedUser.person_last_name;
        // Create the user. SCAPI will create a person.
        newedituser.user
          .create()
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: $filter("translate")("api.USER_SUCCESSFULLY_SAVED"),
              });
              deferred.resolve();
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: $filter("translate")("api.USER_SAVE_FAILED"),
                json: error,
              });
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        // Update the user first since the email has a unique constraint
        newedituser.user
          .update()
          .then(
            function () {
              // Update person
              var person = PersonUsersService.convertToSCAPIPerson(
                $scope.personUsers.currentPerson.selectedUser
              );
              PersonsService.update(person)
                .then(
                  function () {
                    $rootScope.alerts.push({
                      type: "success",
                      text: $filter("translate")("api.USER_SUCCESSFULLY_SAVED"),
                    });
                    deferred.resolve();
                  },
                  function (error) {
                    $rootScope.alerts.push({
                      type: "error",
                      text: "Error saving person",
                      json: error,
                    });
                    deferred.reject(error);
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              deferred.reject(error);
              $rootScope.alerts.push({
                type: "error",
                text: $filter("translate")("api.USER_SAVE_FAILED"),
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      return deferred.promise;
    };

    newedituser.cancel = function () {
      $window.history.back();
    };

    newedituser.ifPanelsExist = function (system) {
      return system.panels.length > 0;
    };

    newedituser.authorityLevels = [
      { name: "Administrator", code: "admin" },
      { name: "Standard", code: null },
      { name: "Access Only", code: "access_only" },
    ];

    $scope.removeUserRoleActions = function () {
      for (var i = 0; i < newedituser.user.user_permissions.length; i++) {
        if (!newedituser.user.user_permissions[i].site_id) {
          newedituser.user.user_permissions[
            i
          ].multi_panel_user_codes_enabled = false;
          newedituser.user.user_permissions[
            i
          ].multi_panel_schedules_enabled = false;
          newedituser.user.user_permissions[i].arming_enabled = false;
        } else {
          selectSiteUserPermissions(newedituser.user.user_permissions[i]);
        }
      }
    };

    init();
  },
]);
