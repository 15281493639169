/**
 * @generated SignedSource<<7c3be12d6fa28df000c895ee94970b19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ZoneType = "ARMING" | "AUXILIARY_1" | "AUXILIARY_2" | "BLANK" | "CARBON_MONOXIDE" | "DAY" | "DOORBELL" | "EMERGENCY" | "EXIT" | "FINAL_EXIT" | "FIRE" | "FIRE_VERIFY" | "INSTANT" | "NIGHT" | "PANIC" | "SUPERVISORY" | "TAMPER" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type ZoneInformationPrewarnKeypadsField_zone$data = {
  readonly number: string;
  readonly prewarnKeypads: string;
  readonly type: ZoneType;
  readonly " $fragmentType": "ZoneInformationPrewarnKeypadsField_zone";
};
export type ZoneInformationPrewarnKeypadsField_zone$key = {
  readonly " $data"?: ZoneInformationPrewarnKeypadsField_zone$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZoneInformationPrewarnKeypadsField_zone">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZoneInformationPrewarnKeypadsField_zone",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prewarnKeypads",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Zone",
  "abstractKey": null
};

(node as any).hash = "b490e38bde896b7f1128e5896d57925c";

export default node;
