/**
 * @generated SignedSource<<aa3843f4985f7c9ad6ff081881edaf67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type NetworkOptionsWifiSecurity = "NONE" | "UNSUPPORTED" | "WEP" | "WPA";
import { FragmentRefs } from "relay-runtime";
export type XT75NetworkOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data = {
  readonly networkOptions: {
    readonly ddpPassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly dhcpEnabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly dnsServer: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly enableDdp: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly gatewayAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly included: boolean;
    readonly ipv6Address: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Dns: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Enabled: {
      readonly data: boolean | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Gateway: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly ipv6Prefix: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly listenPort734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly localIpAddress: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly passphrase734N: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly programmingPort: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly subnetMask: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiPassphrase: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiSecurity: {
      readonly data: NetworkOptionsWifiSecurity | null;
      readonly included: boolean | null;
    } | null;
    readonly wifiSsid: {
      readonly data: string | null;
      readonly included: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "XT75NetworkOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts";
};
export type XT75NetworkOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$key = {
  readonly " $data"?: XT75NetworkOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75NetworkOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "XT75NetworkOptionsProgrammingConceptFormInline_xt75ProgrammingTemplateConcepts"
};

(node as any).hash = "d78f709c0ca7954f20fe566dbc0cc30f";

export default node;
