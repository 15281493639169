import graphql from "babel-plugin-relay/macro";
import { MessagePosition } from "common/components/web/Tooltip";
import { Flex } from "components/DaStyledElements";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import { GenericPageFallback } from "components/GenericPageFallback";
import Icon from "components/Icon";
import InlineTooltip from "components/InlineTooltip";
import TextInput from "components/Inputs/TextInput";
import Modal from "components/Modal";
import {
  DeleteConfirmModal,
  GenericConfirmModal,
} from "components/Modal/ConfirmModal";
import RecorderTestConnectionModal from "components/RecorderTestConnectionModal";
import Select from "components/Select";
import { Switch } from "components/SiteForm/FormFields";
import { VarStatusBadgeWithAutoRefresh } from "components/VarStatusBadge";
import { AlertsContextProvider, useShowAlert } from "contexts/AlertsContext";
import React, { ChangeEvent, Suspense, useMemo, useRef } from "react";
import {
  commitLocalUpdate,
  RelayEnvironmentProvider,
  useFragment,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { react2angular } from "react2angular";
import {
  idAsString,
  toControlSystemId,
  toVarHubId,
  toVarHubSettingsId,
  VarHubPanelConnectionStatus,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import DownloadLogsButton from "./DownloadLogs";
import PanelConnectionBadge from "./PanelConnectionBadge";
import PingPanelButton from "./PingPanel";
import SettingsOption from "./SettingsOption";
import ViewConfigurationButton from "./ViewConfiguration";
import WsDiscoveryProbeButton from "./WsDiscoveryProbe";
import { RecorderEditDealerChargesQuery } from "./__generated__/RecorderEditDealerChargesQuery.graphql";
import { RecorderEditDeleteVarHubMutation } from "./__generated__/RecorderEditDeleteVarHubMutation.graphql";
import { RecorderEditPanelDataQuery } from "./__generated__/RecorderEditPanelDataQuery.graphql";
import { RecorderEditQuery } from "./__generated__/RecorderEditQuery.graphql";
import { RecorderEditRestartVarHubMutation } from "./__generated__/RecorderEditRestartVarHubMutation.graphql";
import { RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub$key } from "./__generated__/RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub.graphql";
import { RecorderEditSettingsQuery } from "./__generated__/RecorderEditSettingsQuery.graphql";
import { RecorderEditUpdatePanelIntegrationMutation } from "./__generated__/RecorderEditUpdatePanelIntegrationMutation.graphql";
import { RecorderEditUpdateVarHubMutation } from "./__generated__/RecorderEditUpdateVarHubMutation.graphql";

const updateVarHubSettingsMutation = graphql`
  mutation RecorderEditUpdateVarHubSettingsMutation(
    $systemId: String!
    $hubId: String!
    $hubSettings: UpdateVarHubSettingsInput!
  ) {
    updateVarHubSettings(
      hubId: $hubId
      systemId: $systemId
      hubSettings: $hubSettings
    ) {
      __typename
      ... on UpdateVarHubSettingsSuccessPayload {
        status
        # ToDO return the data
      }
      ... on UpdateVarHubSettingsErrorPayload {
        type
        message
      }
    }
  }
`;

export default function RecorderEditRoot({
  RelayService,
  $state,
  UserService,
  $rootScope,
  CAMECT_TIME_ZONES,
}: any) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <React.Suspense fallback={<GenericPageFallback />}>
      <RelayEnvironmentProvider environment={environment}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <RecorderEdit
            state={$state}
            UserService={UserService}
            timeZones={CAMECT_TIME_ZONES}
          />
        </AlertsContextProvider>
      </RelayEnvironmentProvider>
    </React.Suspense>
  );
}

type SecondaryNetworkTypeMapping = {
  [key: string]: string;
};

const secondaryNetworkTypes: SecondaryNetworkTypeMapping = {
  DHCP: "dhcp",
  "Static IP Address": "staticip",
};

const RecorderEdit = ({
  state,
  UserService,
  timeZones,
}: {
  state: any;
  UserService: any;
  timeZones: string[];
}) => {
  const canViewCamectTroubleshootingTools = () => {
    return UserService.isTechSupport() || UserService.isAdmin();
  };
  const rolePreventsToggleAutoDiscovery = () => {
    return UserService.isTechSupport() || UserService.isAdmin();
  };

  const varHubId = idAsString(
    toVarHubId(UserService.control_system_id, state.params.var_hub_id)
  );
  const varHubSettingsId = idAsString(
    toVarHubSettingsId(UserService.control_system_id, state.params.var_hub_id)
  );
  const relayEnvironment = useRelayEnvironment();
  const settings = useLazyLoadQuery<RecorderEditSettingsQuery>(
    graphql`
      query RecorderEditSettingsQuery($varHubSettingsId: ID!) {
        getVarHubSettings(id: $varHubSettingsId) {
          ... on GetVarHubSettingsSuccessPayload {
            varHubSettings: settings {
              id
              systemId
              hubId
              settings {
                hardwareModel
                camectHubId
                hubName
                timeLocation
                streamingSettings {
                  enableAudio
                  maxFootageLifeSecs
                  saveAudio
                }
                alertSettings {
                  disableAlert
                  disableAutoSuppression
                  disableMultiNotifications
                  disableObjectBox
                  disableSending
                  enableSensitiveMotion
                  interestingObject
                  offlineAlertDelaySecs
                  monitoring {
                    immix {
                      immixCams {
                        camectCamId
                        camName
                        immixInput
                        deviceId
                      }
                      deviceId
                      password
                      recipientDomain
                      sendAutoSuppression
                      smtpPort
                      smtpServer
                      user
                    }
                  }
                }
              }
              __typename
            }
          }
        }
      }
    `,
    { varHubSettingsId },
    { fetchPolicy: "network-only" }
  );
  const [updateVarHubSettings, isUpdatingImmix] = useMutation(
    updateVarHubSettingsMutation
  );

  const [isTestConnectionModalOpen, setIsTestConnectionModalOpen] =
    React.useState(false);

  const memoizedSettings = useMemo(() => settings, [settings]);
  const immixSettings =
    memoizedSettings.getVarHubSettings.varHubSettings?.settings.alertSettings
      ?.monitoring?.immix;
  const handleCancel = () => {
    setShowSubnetMaskModal(false);
    setDeviceIdError("");
    setSmtpServerError("");
    setSmtpPortError("");
    setUserError("");
    setPasswordError("");
    setDeviceId(String(immixSettings?.deviceId) ?? "0");
    setSmtpServer(immixSettings?.smtpServer ?? "");
    setSmtpPort(String(immixSettings?.smtpPort) ?? "587");
    setUser(immixSettings?.user ?? "");
    setPassword(
      immixSettings?.password !== undefined && immixSettings?.password !== ""
        ? "******"
        : ""
    );
    setSendAutoSuppression(immixSettings?.sendAutoSuppression ?? true);
    setImmixCamera(initialImmixCamera);
    setImmixCameraInputId("");
    handleRemoveAddedCameras();
  };

  const data = useLazyLoadQuery<RecorderEditQuery>(
    graphql`
      query RecorderEditQuery($varHubId: ID!) {
        getVarHubFromId(id: $varHubId) {
          ... on GetVarHubSuccessPayload {
            varHub: hub {
              id
              camectHubId
              hubId
              systemId
              hubName
              macAddress
              aiModelVersion
              osVersion
              ownerEmail
              softwareVersion
              timeLocation
              privateIp
              hardwareModel
              hardwareModelDisplayName
              megapixelThresholdWarning
              megapixelThresholdCritical
              supportedMegapixels
              panelIntegrationVersion
              panelIntegrationUptime
              panelIntegrationLastInbound
              panelIntegrationLastOutbound
              secondaryNetworkType
              secondaryNetworkIP
              secondaryNetworkSubnetMask
              isOnline
              panelConnectionEnabled
              lastUpdatedAt
              immixIntegrationEnabled
              panelIntegrationPanelDiscoveryEnabled
              panelIntegrationPanelIpAddress
              panelIntegrationSystemUptime
              panelIntegrationUptime
              panelIntegrationLastOutbound
              panelIntegrationLastInbound
              panelIntegrationGatewayIpAddress
              cameras {
                id
                cameraName
                ipAddress
                camectCamId
                cameraId
                addedToDB
                detectionRegions {
                  id
                  loiteringPersonSeconds
                }
              }
              ...RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub
              ...PanelConnectionBadge_varHub
              ...VarStatusBadge_varHub
              __typename
            }
          }
        }
      }
    `,
    { varHubId },
    { fetchPolicy: "network-only" }
  );
  const varHub = data.getVarHubFromId?.varHub;
  const immixCams =
    settings.getVarHubSettings.varHubSettings?.settings.alertSettings
      ?.monitoring?.immix?.immixCams;
  const [initialCameraList, setinitialCameraList] = React.useState(
    varHub?.cameras.filter((camera) => camera.addedToDB === true) ?? []
  );
  const [cameraList, setCameraList] = React.useState(initialCameraList);
  const panelData = useLazyLoadQuery<RecorderEditPanelDataQuery>(
    graphql`
      query RecorderEditPanelDataQuery($databaseId: Int!) {
        controlSystemByDatabaseId(databaseId: $databaseId) {
          id
          panel {
            id
            communicationPaths {
              commType
            }
          }
        }
      }
    `,
    {
      databaseId: Number(UserService.control_system_id),
    },
    { fetchPolicy: "store-and-network" }
  );

  const panelHasWifiEnabled = React.useMemo(
    () =>
      panelData.controlSystemByDatabaseId?.panel?.communicationPaths?.some(
        (path) => path.commType === "WIFI"
      ),
    [panelData]
  );

  const deleteVarHubMutation = graphql`
    mutation RecorderEditDeleteVarHubMutation(
      $hubId: String!
      $systemId: String!
    ) {
      deleteVarHub(hubId: $hubId, systemId: $systemId) {
        __typename
        ... on DeleteVarHubSuccessPayload {
          status
        }
        ... on DeleteVarHubErrorPayload {
          type
          message
        }
      }
    }
  `;
  // TODO set the price of IMMIX to be pulled from here once it is added to the call
  const dealerChargesData = useLazyLoadQuery<RecorderEditDealerChargesQuery>(
    graphql`
      query RecorderEditDealerChargesQuery($dealerId: String!) {
        dealerChargesQuery(dealerId: $dealerId) {
          ... on DealerCharges {
            AlarmVisionChannel
          }
          ... on UnknownError {
            type
          }
        }
      }
    `,
    { dealerId: UserService.dealerInfo.id.toString() }
  );

  const [deleteVarHub, isDeleting] =
    useMutation<RecorderEditDeleteVarHubMutation>(deleteVarHubMutation);

  function convertNetworkTypeToDescription(value: string): string {
    const correspondingKey: string | undefined = Object.keys(
      secondaryNetworkTypes
    ).find((key) => secondaryNetworkTypes[key] === value);
    return correspondingKey ? correspondingKey : "Static IP Address";
  }

  const [confirmingDelete, setConfirmingDelete] = React.useState(false);
  const [confirmingRestart, setConfirmingRestart] = React.useState(false);
  const [varHubName, setVarHubName] = React.useState(varHub?.hubName ?? "");
  const [varStaticIp, setVarStaticIp] = React.useState(
    varHub?.secondaryNetworkIP ?? ""
  );
  const [varSubnetMask, setVarSubnetMask] = React.useState(
    varHub?.secondaryNetworkSubnetMask ?? ""
  );
  const [showSubnetMaskModal, setShowSubnetMaskModal] = React.useState(false);
  const [isDownloadLogsModalOpen, setIsDownloadLogsModalOpen] =
    React.useState(false);
  const [isViewConfigurationModalOpen, setIsViewConfigurationModalOpen] =
    React.useState(false);
  const [isWsDiscoveryProbeModalOpen, setIsWsDiscoveryProbeModalOpen] =
    React.useState(false);
  const [showImmixButton, setShowImmixButton] = React.useState(
    varHub?.immixIntegrationEnabled ?? false
  );
  const [secondaryNetworkOption, setSecondaryNetworkOption] = React.useState({
    type: varHub?.secondaryNetworkType ?? "",
    description: varHub?.secondaryNetworkType
      ? convertNetworkTypeToDescription(varHub.secondaryNetworkType)
      : "Static IP Address",
  });
  const [staticIpError, setStaticIpError] = React.useState("");
  const [subnetMaskError, setSubnetMaskError] = React.useState("");
  const disablingPanelConnection = React.useRef(false);
  const retryingPanelConnection = React.useRef(false);

  const [deviceId, setDeviceId] = React.useState(
    String(immixSettings?.deviceId) ?? "0"
  );
  const [smtpServer, setSmtpServer] = React.useState(
    immixSettings?.smtpServer ?? ""
  );
  const [smtpPort, setSmtpPort] = React.useState(
    String(immixSettings?.smtpPort) ?? "587"
  );
  const [user, setUser] = React.useState(immixSettings?.user ?? "");
  const [password, setPassword] = React.useState(
    immixSettings?.password !== undefined && immixSettings?.password !== ""
      ? "******"
      : ""
  );
  const [recipientDomain, setRecipientDomain] = React.useState(
    immixSettings?.recipientDomain
  );
  const [sendAutoSuppression, setSendAutoSuppression] = React.useState(
    immixSettings?.sendAutoSuppression ?? false
  );
  const areFieldsEmpty = () => {
    return !deviceId || !smtpServer || !smtpPort;
  };
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSendAutoSuppression(event.target.checked); // Update state variable based on checkbox status
  };
  const handleInitalPassword = () => {
    if (password === "******") {
      setPassword("");
    }
  };

  const updateVarHubMutation = graphql`
    mutation RecorderEditUpdateVarHubMutation($hub: UpdateVarHubInput!) {
      updateVarHub(hub: $hub) {
        __typename
        ... on UpdateVarHubSuccessPayload {
          status
        }
        ... on UpdateVarHubErrorPayload {
          type
          message
        }
      }
    }
  `;

  const [updateVarHub, isUpdating] =
    useMutation<RecorderEditUpdateVarHubMutation>(updateVarHubMutation);
  const restartVarHubMutation = graphql`
    mutation RecorderEditRestartVarHubMutation(
      $hubId: String!
      $systemId: String!
    ) {
      restartVarHub(hubId: $hubId, systemId: $systemId) {
        __typename
        ... on RestartVarHubSuccessPayload {
          status
        }
        ... on RestartVarHubErrorPayload {
          type
          message
        }
      }
    }
  `;

  const [restartVarHub, isRestarting] =
    useMutation<RecorderEditRestartVarHubMutation>(restartVarHubMutation);

  const updatePanelIntegrationMutation = graphql`
    mutation RecorderEditUpdatePanelIntegrationMutation(
      $hubId: String!
      $systemId: String!
      $panelConnectionEnabled: Boolean!
    ) {
      updatePanelIntegration(
        hubId: $hubId
        systemId: $systemId
        enabled: $panelConnectionEnabled
      ) {
        __typename
        ... on UpdatePanelIntegrationSuccessPayload {
          enabled
        }
        ... on UpdatePanelIntegrationErrorPayload {
          type
          message
        }
      }
    }
  `;

  const [updatePanelIntegration, isUpdatingPanelIntegration] =
    useMutation<RecorderEditUpdatePanelIntegrationMutation>(
      updatePanelIntegrationMutation
    );

  const showAlert = useShowAlert();
  const staticIpRef = useRef<HTMLInputElement>(null);
  const subnetMaskRef = useRef<HTMLInputElement>(null);
  // regex pattern for ip addresses, ex 1.1.1.1 192.168.0.23 etc
  const ipPattern = "^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$";
  const ipPatternJava = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;
  // regex pattern, matches any web address that doesnt have a scheme
  const webAddressPattern =
    /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  const webAddressSchemePattern = /^\w+:\//;

  const handleDropdownChange = (selectedOption: string) => {
    const type = secondaryNetworkTypes[selectedOption];
    setSecondaryNetworkOption({
      type: type,
      description: selectedOption,
    });
  };

  const handleCameraInputId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newNum = event.target.value;
    setImmixCameraInputId(newNum);
  };
  const handleCameraDropdown = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedCamera = cameraList[selectedIndex]; // Get the selected camera object
    if (selectedCamera) {
      setImmixCamera(selectedCamera);
    }
  };

  const handleSwitchChange = () => {
    setPassword("");
    setRecipientDomain("");
    setSmtpPort("0");
    setSendAutoSuppression(false);
    setDeviceId("0");
    setSmtpServer("");
    setUser("");
    setConfiguredCameraList([]);
    setCameraList(initialCameraList);
    setImmixCamera(
      cameraList[0] ?? {
        id: "",
        addedToDB: false,
        camectCamId: "",
        cameraId: null,
        cameraName: "",
        ipAddress: "",
        detectionRegions: [],
      }
    );

    setShowImmixButton((prevState) => !prevState);
    updateVarHubSettings({
      variables: {
        hubId: String(settings.getVarHubSettings.varHubSettings?.hubId ?? ""),
        systemId: String(
          settings.getVarHubSettings.varHubSettings?.systemId ?? ""
        ),
        hubSettings: {
          immixEnabled: !showImmixButton,
        },
      },
      onCompleted: (response) => {},
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Failed to enable XV Gateway Immix Settings.",
          suppressTitleCasing: true,
        });
      },
    });
  };

  const [varHubTimeZone, setVarHubTimeZone] = React.useState(
    timeZones.find(
      (tz) =>
        tz === varHub?.timeLocation ??
        Intl.DateTimeFormat().resolvedOptions().timeZone // default to browser time zone if we can't find the hub's time zone
    ) ?? "US/Central" // default to US/Central if we can't find the hub's or computers time zone
  );

  const { isXr, isXt75 } = resolvePanelType(
    UserService.controlSystem.panels[0].hardware_model
  );

  const canUseIntegration = isXr || isXt75;

  const isEditable = UserService.canEditVideoDevice();

  const firmwareSupportsPanelIntegration = panelVersionGTOE(
    221,
    UserService.controlSystem.panels[0].software_version
  );

  const goBackToControlSystem = () => {
    state.go("app.control_system", {
      customer_id: UserService.customer_id,
      control_system_id: UserService.control_system_id,
    });
  };

  const goToRemoteUpdate = () => {
    state.go("app.control_system.remote_update", {
      customer_id: UserService.customer_id,
      control_system_id: UserService.control_system_id,
    });
  };

  const remoteUpdateHref = state.href("app.control_system.remote_update", {
    customer_id: UserService.customer_id,
    control_system_id: UserService.control_system_id,
  });

  const goToProgramming = () => {
    state.go("app.panel_programming.programming", {
      customer_id: UserService.customer_id,
      control_system_id: UserService.control_system_id,
    });
  };

  const programmingHref = state.href("app.panel_programming.programming", {
    customer_id: UserService.customer_id,
    control_system_id: UserService.control_system_id,
  });

  const enablePanelIntegration = async (enable: boolean) => {
    await updatePanelIntegration({
      variables: {
        hubId: String(varHub?.hubId ?? ""),
        systemId: String(varHub?.systemId ?? ""),
        panelConnectionEnabled: enable,
      },
      onCompleted: (response) => {
        if (
          response.updatePanelIntegration.__typename ===
          "UpdatePanelIntegrationSuccessPayload"
        ) {
          commitLocalUpdate(relayEnvironment, (store) => {
            const varHubRecord = store.get(varHubId);
            if (varHubRecord) {
              varHubRecord.setValue(enable, "panelConnectionEnabled");
              if (!enable) {
                varHubRecord.setValue("UNKNOWN", "panelConnectionStatus");
              }
            }
          });
          showAlert({
            type: "success",
            text: "Successfully updated XV Gateway Panel Integration.",
            suppressTitleCasing: true,
          });
        } else if (
          response.updatePanelIntegration.__typename ===
          "UpdatePanelIntegrationErrorPayload"
        ) {
          showAlert({
            type: "error",
            text: `Failed to update XV Gateway Panel Integration.: ${response.updatePanelIntegration.message}`,
            suppressTitleCasing: true,
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Failed to update XV Gateway Panel Integration.",
          suppressTitleCasing: true,
        });
      },
    });
  };
  interface Camera {
    id: string;
    cameraObj: ImmixCamera;
  }
  interface ImmixCamera {
    readonly id: string;
    readonly addedToDB: boolean;
    readonly camectCamId: string;
    readonly cameraId: number | null;
    readonly cameraName: string;
    readonly ipAddress: string;
    readonly detectionRegions: readonly ({
      readonly id: string;
      readonly loiteringPersonSeconds: number | null;
    } | null)[];
  }
  const [gatewayIpAddress, setGatewayIpAddress] = React.useState(
    varHub?.panelIntegrationGatewayIpAddress
  );
  const [panelIpAddress, setPanelIpAddress] = React.useState(
    varHub?.panelIntegrationPanelIpAddress
  );
  const [deviceIdError, setDeviceIdError] = React.useState("");
  const [panelIpAddressError, setPanelIpAddressError] = React.useState("");
  const [xvGatewayIpAddressError, setXvGatewayIpAddressError] =
    React.useState("");
  const [smtpServerError, setSmtpServerError] = React.useState("");
  const [smtpPortError, setSmtpPortError] = React.useState("");
  const [userError, setUserError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const initialImmixCamera = cameraList[0] ?? {
    id: "",
    addedToDB: false,
    camectCamId: "",
    cameraId: null,
    cameraName: "",
    ipAddress: "",
    detectionRegions: [],
  };
  const [configuredCameraList, setConfiguredCameraList] = React.useState<
    Camera[]
  >([]);
  // these two are the selectable camera from the drop down, and the number you type in in the immix modal
  const [immixCamera, setImmixCamera] = React.useState(initialImmixCamera);
  const [immixCameraInputId, setImmixCameraInputId] = React.useState("");

  const handleRemoveAddedCameras = () => {
    const cameraMap = new Map(
      initialCameraList.map((camera) => [camera.camectCamId, camera])
    );

    const updatedConfiguredCameraList: Camera[] = [];
    const remainingCameras = new Map(cameraMap);

    // Loop through and process cameras
    immixCams?.forEach((immixCam) => {
      if (immixCam) {
        const matchingCamera = cameraMap.get(immixCam.camectCamId ?? "");
        if (matchingCamera) {
          // Create a new configured camera object
          const newCamera = {
            id: String(immixCam.immixInput),
            cameraObj: matchingCamera,
          };
          updatedConfiguredCameraList.push(newCamera);
          remainingCameras.delete(matchingCamera.camectCamId); // Remove matched cameras from the remaining list
        }
      }
    });

    // Convert remaining cameras map back to an array
    const updatedCameraList = Array.from(remainingCameras.values());

    // Sort the updated configured camera list by ID
    updatedConfiguredCameraList.sort((a, b) => parseInt(a.id) - parseInt(b.id));

    // Update state with the new lists
    setConfiguredCameraList(updatedConfiguredCameraList);
    setCameraList(updatedCameraList);

    // Set the first available or a default camera object
    setImmixCamera(
      updatedCameraList[0] ?? {
        id: "",
        addedToDB: false,
        camectCamId: "",
        cameraId: null,
        cameraName: "",
        ipAddress: "",
        detectionRegions: [],
      }
    );
  };
  React.useEffect(() => {
    handleRemoveAddedCameras();
  }, [settings]);
  const handleImmixCameraAdd = () => {
    const newCamera = {
      id: immixCameraInputId,
      cameraObj: immixCamera,
    };
    // add the camera to the list that is sent to camect
    setConfiguredCameraList([...configuredCameraList, newCamera]);
    setImmixCameraInputId("");
    // remove the camera from the list of options you can select
    const updatedCameras = cameraList.filter(
      (camera) => camera.camectCamId !== newCamera.cameraObj.camectCamId
    );
    setCameraList(updatedCameras);
    // set the current selected option to the next camera or a blank camera if empty
    const newImmixCamera =
      updatedCameras.length > 0
        ? updatedCameras[0]
        : {
            id: "",
            addedToDB: false,
            camectCamId: "",
            cameraId: null,
            cameraName: "",
            ipAddress: "",
            detectionRegions: [],
          };
    setImmixCamera(newImmixCamera);
  };
  const handleDeleteCam = (cameraIdToDelete: string) => {
    const cameraToDelete = configuredCameraList.find(
      (camera) => camera.cameraObj.camectCamId === cameraIdToDelete
    );
    if (!cameraToDelete) {
      return; // Camera not found, handle error or return early
    }
    const newImmixCamera = cameraToDelete.cameraObj;
    setImmixCamera(newImmixCamera);
    // Add the deleted camera back to cameraList, to be selectable
    setCameraList([...cameraList, cameraToDelete.cameraObj]);
    // Remove the camera from configuredCameraList, so it is not sent to camect
    const updatedConfiguredCameraList = configuredCameraList.filter(
      (camera) => camera.cameraObj.camectCamId !== cameraIdToDelete
    );
    setConfiguredCameraList(updatedConfiguredCameraList);
  };

  const handleModalSave = () => {
    setDeviceIdError(
      deviceId.trim() === ""
        ? "Error: Please enter a Device ID number."
        : isNaN(Number(deviceId))
        ? "Invalid Device ID Configuration: Please enter a numeric value."
        : ""
    );
    setSmtpServerError(
      smtpServer.trim() === ""
        ? "Error: Please enter a SMTP Server IP address."
        : !ipPatternJava.test(smtpServer) && !webAddressPattern.test(smtpServer)
        ? "Invalid SMTP Server Configuration: Please enter a valid IP or web address."
        : webAddressSchemePattern.test(smtpServer)
        ? "Invalid Web Address: Please do not include the scheme (*://) in the web address"
        : ""
    );
    setSmtpPortError(
      smtpPort.trim() === ""
        ? "Error: Please enter a SMTP port number."
        : isNaN(Number(smtpPort))
        ? "Invalid SMTP Port Configuration: Please enter a numeric value."
        : ""
    );
    // blank for now, but here in case we want to add checks later
    setUserError("");
    setPasswordError(
      password === "******" ? "Error: Please re-enter password" : ""
    );
    //!ipPatternJava.test(smtpServer) ||
    if (
      deviceId.trim() === "" ||
      smtpServer.trim() === "" ||
      smtpPort.trim() === "" ||
      isNaN(Number(deviceId)) ||
      (!ipPatternJava.test(smtpServer) &&
        !webAddressPattern.test(smtpServer)) ||
      webAddressSchemePattern.test(smtpServer) ||
      isNaN(Number(smtpPort)) ||
      password === "******" ||
      panelIpAddressError ||
      xvGatewayIpAddressError
    ) {
      // If any error message is not empty, do not proceed
      return;
    }
    updateVarHubSettings({
      variables: {
        hubId: String(settings.getVarHubSettings.varHubSettings?.hubId ?? ""),
        systemId: String(
          settings.getVarHubSettings.varHubSettings?.systemId ?? ""
        ),
        hubSettings: {
          deviceId: parseInt(deviceId),
          password: password,
          recipientDomain: recipientDomain,
          sendAutoSuppression: sendAutoSuppression,
          smtpPort: parseInt(smtpPort),
          smtpServer: smtpServer,
          user: user,
          immixEnabled: true,
          immixCams: configuredCameraList.map((cam) => ({
            camectCamId: cam.cameraObj.camectCamId,
            camName: cam.cameraObj.cameraName,
            immixInput: parseInt(cam.id),
            deviceId: parseInt(deviceId),
          })),
        },
      },
      onCompleted: (response) => {
        setinitialCameraList(cameraList);
        handleSave();
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Failed to update XV Gateway Immix Settings.",
          suppressTitleCasing: true,
        });
      },
    });
  };
  const handleSave = () => {
    setPanelIpAddressError(
      panelIpAddress?.trim() === ""
        ? "Error: Please enter a Panel IP Address."
        : panelIpAddress && !ipPatternJava.test(panelIpAddress)
        ? "Invalid SMTP Server Configuration: Please enter a valid  panel IP address."
        : ""
    );
    setXvGatewayIpAddressError(
      gatewayIpAddress?.trim() === ""
        ? "Error: Please enter a Panel IP Address."
        : gatewayIpAddress && !ipPatternJava.test(gatewayIpAddress)
        ? "Invalid SMTP Server Configuration: Please enter a valid  xv gateway IP address."
        : ""
    );
    setStaticIpError(
      staticIpRef.current?.validity.valueMissing
        ? "Error: Please enter a static IP address."
        : staticIpRef.current?.validity.patternMismatch
        ? "Invalid IP Address Configuration: Please enter a valid IP address."
        : ""
    );
    setSubnetMaskError(
      subnetMaskRef.current?.validity.valueMissing
        ? "Error: Please enter a subnet mask."
        : subnetMaskRef.current?.validity.patternMismatch
        ? "Invalid Subnet Mask: Please enter a valid subnet mask."
        : ""
    );
    if (!showImmixButton) {
      updateVarHubSettings({
        variables: {
          hubId: String(settings.getVarHubSettings.varHubSettings?.hubId ?? ""),
          systemId: String(
            settings.getVarHubSettings.varHubSettings?.systemId ?? ""
          ),
          hubSettings: {
            immixEnabled: false,
          },
        },
        onError: (error) => {
          showAlert({
            type: "error",
            text: "Failed to disable XV Gateway Immix Setting.",
            suppressTitleCasing: true,
          });
        },
      });
    }

    if (
      deviceId.trim() === "" ||
      isNaN(Number(deviceId)) ||
      isNaN(Number(smtpPort)) ||
      password === "******" ||
      (gatewayIpAddress && !ipPatternJava.test(gatewayIpAddress)) ||
      (panelIpAddress && !ipPatternJava.test(panelIpAddress)) ||
      xvGatewayIpAddressError.length !== 0
    ) {
      return;
    }
    updateVarHub({
      variables: {
        hub: {
          hubId: String(varHub?.hubId ?? ""),
          systemId: String(varHub?.systemId ?? ""),
          timeLocation:
            varHubTimeZone ?? varHub?.timeLocation ?? "America/Chicago",
          hubName: varHubName ?? varHub?.hubName ?? "",
          secondaryNetworkType:
            secondaryNetworkOption.type ?? varHub?.secondaryNetworkType ?? "",
          secondaryNetworkIP: varStaticIp ?? varHub?.secondaryNetworkIP ?? "",
          secondaryNetworkSubnetMask:
            varSubnetMask ?? varHub?.secondaryNetworkSubnetMask ?? "",
          cameras: varHub?.cameras ?? [],
          panelIntegrationGatewayIpAddress: gatewayIpAddress ?? "",
          panelIntegrationPanelDiscoveryEnabled: isAutoDiscoveryEnabled,
          panelIntegrationPanelIpAddress: panelIpAddress ?? "",
        },
      },
      onCompleted: (response) => {
        if (response.updateVarHub.__typename === "UpdateVarHubSuccessPayload") {
          showAlert({
            type: "success",
            text: "Successfully updated XV Gateway.",
            suppressTitleCasing: true,
          });

          goBackToControlSystem();
        } else {
          showAlert({
            type: "error",
            text: "Failed to update XV Gateway.",
            suppressTitleCasing: true,
          });
        }
      },
      onError: (error) => {
        showAlert({
          type: "error",
          text: "Failed to update XV Gateway.",
          suppressTitleCasing: true,
        });
      },
    });
  };

  const formatUptime = (time: string) =>
    time.replace(/(d+)([a-zA-z])/g, "$1,$2 ").trim;
  const [isPingPanelModalOpen, setIsPingPanelModalOpen] = React.useState(false);

  const [isAutoDiscoveryEnabled, setIsAutoDiscoveryEnabled] = React.useState(
    varHub?.panelIntegrationPanelDiscoveryEnabled
  );

  const [isDisabled, setIsDisabled] = React.useState(isAutoDiscoveryEnabled);

  const handleAutoDiscoverySwitchToggled = () => {
    setIsAutoDiscoveryEnabled((prevState) => !prevState);
    setIsDisabled((prevState) => !prevState);
  };

  return (
    <>
      <Header>
        <div className="row">
          <div className="page-header">
            <div className="page-header__left">
              <div className="page-header__title">
                <span>{`${varHub?.hubName} Settings`}</span>
                <span style={{ fontSize: "1.4rem" }}>
                  {varHub && <VarStatusBadgeWithAutoRefresh varHub={varHub} />}
                </span>
              </div>
            </div>
            <div className="page-header__right">
              <button
                className="btn btn-default btn-sm"
                onClick={goBackToControlSystem}
                disabled={isDeleting || isUpdating || isRestarting}
              >
                Back
              </button>
              {isEditable ? (
                <button
                  className="btn btn-default btn-sm"
                  disabled={
                    isDeleting ||
                    isUpdating ||
                    isRestarting ||
                    isUpdatingPanelIntegration
                  }
                  onClick={() => {
                    setConfirmingRestart(true);
                  }}
                >
                  {isRestarting ? `Restarting` : `Restart`}
                </button>
              ) : null}
              {UserService.canDeleteVideoDevice() ? (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => setConfirmingDelete(true)}
                  disabled={isDeleting || isUpdating || isRestarting}
                >
                  Delete
                </button>
              ) : null}
              {isEditable ? (
                <button
                  className="btn btn-dmp btn-sm"
                  disabled={
                    isDeleting ||
                    isUpdating ||
                    isRestarting ||
                    isUpdatingPanelIntegration ||
                    !gatewayIpAddress ||
                    !panelIpAddress
                  }
                  onClick={handleSave}
                >
                  {isUpdating ? `Saving...` : `Save`}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </Header>
      <Grid>
        <fieldset>
          <MainEdit>
            <InnerHeader>
              <InputContainer>
                <InputLabel>
                  Name <span className="text-danger">*</span>
                </InputLabel>
                <TextInput
                  disabled={!isEditable}
                  type="text"
                  name="name"
                  value={varHubName}
                  maxLength={50}
                  className="form-control form-control-377"
                  onChange={({ target: { value } }) => setVarHubName(value)}
                  required
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>Time Zone</InputLabel>
                <Select
                  disabled={!isEditable}
                  className="form-control form-control-377"
                  value={varHubTimeZone}
                  onChange={({ target: { value } }) =>
                    setVarHubTimeZone(() => value)
                  }
                >
                  {timeZones.map((timeZone, index) => (
                    <Select.Option
                      key={`${timeZone}-${index}`}
                      value={timeZone}
                    >
                      {timeZone.replace(/_/g, " ")}
                    </Select.Option>
                  ))}
                </Select>
              </InputContainer>
            </InnerHeader>
            <HeadingWithGreyLine></HeadingWithGreyLine>
            <MainSettingsContainer>
              {canUseIntegration ? (
                <LeftSettings>
                  <SettingsTitles>Options</SettingsTitles>
                  <Suspense fallback={<GenericPageFallback />}>
                    <PanelCommunicationOptions>
                      <OptionsTextContainer>
                        <Immix>
                          <SwitchContainer>
                            <RadialInfo>
                              {"Allow Communication to XR or XT75 Series Panel"}
                              <InlineTooltip
                                message="Allow the XV Hub to communicate with an XR or XT75 Series panel. Once enabled, cameras added to the XV Hub can create zones on the panel to trigger alarms based on configured analytics. Video actions can also be created to record based on panel events."
                                position={MessagePosition.Right}
                              />
                            </RadialInfo>
                          </SwitchContainer>
                          <OptionsButtonContainer>
                            <TestConButton
                              disabled={false}
                              onClick={() => {
                                setIsTestConnectionModalOpen(true);
                              }}
                              className="btn btn-default"
                              style={{ marginRight: "1rem" }}
                            >
                              Test Connection
                            </TestConButton>
                            {!varHub?.panelConnectionEnabled ? (
                              <AbleButton
                                className="btn btn-dmp"
                                disabled={
                                  !isEditable ||
                                  !firmwareSupportsPanelIntegration ||
                                  panelHasWifiEnabled ||
                                  !varHub?.isOnline ||
                                  isUpdatingPanelIntegration
                                }
                                onClick={() => enablePanelIntegration(true)}
                              >
                                {isUpdatingPanelIntegration
                                  ? `Enabling...`
                                  : `Enable`}
                              </AbleButton>
                            ) : (
                              <AbleButton
                                className="btn btn-danger"
                                disabled={
                                  !isEditable ||
                                  !varHub?.isOnline ||
                                  isUpdatingPanelIntegration
                                }
                                onClick={() => {
                                  disablingPanelConnection.current = true;
                                  enablePanelIntegration(false).then(
                                    () =>
                                      (disablingPanelConnection.current = false)
                                  );
                                }}
                              >
                                {isUpdatingPanelIntegration &&
                                disablingPanelConnection.current
                                  ? `Disabling`
                                  : `Disable`}
                              </AbleButton>
                            )}
                          </OptionsButtonContainer>
                          {isTestConnectionModalOpen ? (
                            <RecorderTestConnectionModal
                              setIsOpen={setIsTestConnectionModalOpen}
                              systemId={UserService.control_system_id}
                              hubId={state.params.var_hub_id}
                              userService={UserService}
                            />
                          ) : null}
                        </Immix>
                        <OptionsDescription style={{ marginBottom: 0 }}>
                          {!firmwareSupportsPanelIntegration ? (
                            <a
                              href={remoteUpdateHref}
                              onClick={goToRemoteUpdate}
                            >
                              Update Required: This feature is only available
                              for XR Series Version 221 or higher.
                            </a>
                          ) : null}
                        </OptionsDescription>
                        <OptionsDescription style={{ marginBottom: 0 }}>
                          {firmwareSupportsPanelIntegration &&
                          panelHasWifiEnabled ? (
                            <a href={programmingHref} onClick={goToProgramming}>
                              This feature is not compatible with Wi-Fi
                              communication. Enable Network communication and
                              try again.
                            </a>
                          ) : null}
                        </OptionsDescription>
                        <Immix>
                          <SwitchContainer>
                            <RadialInfo>
                              {"IMMIX Monitoring"}
                              <InlineTooltip
                                message="Allow the XV Gateway to send alerts to IMMIX when objects are detected. Allows IMMIX to view live video and recorded events."
                                position={MessagePosition.Right}
                              />
                            </RadialInfo>
                          </SwitchContainer>
                          <OptionsButtonContainer>
                            <ImmixButton
                              className="btn btn-default"
                              onClick={() => setShowSubnetMaskModal(true)}
                            >
                              Configure IMMIX
                            </ImmixButton>
                          </OptionsButtonContainer>
                        </Immix>
                        {varHub?.secondaryNetworkType !== "disabled" &&
                        (varHub?.hardwareModel === "xv60" ||
                          varHub?.hardwareModel === "xv96") ? (
                          <>
                            <SettingsTitles>{`Dual Nic Configuration`}</SettingsTitles>
                            <SettingsOption
                              label="Secondary Network"
                              helpFile="https://xvgateway.dmp.support/xv/xv-series-installation-and-setup"
                              radialInfo={`If your private network has a DHCP Server\u000Ado not assign a Static IP Address, or ensure\u000Aa Static IP Address is reserved.`}
                              type="dropdown"
                              value={secondaryNetworkOption.description}
                              dropdownOptions={Object.keys(
                                secondaryNetworkTypes
                              )}
                              setSecondaryNetworkOption={handleDropdownChange}
                            />
                          </>
                        ) : null}
                        {varHub?.secondaryNetworkType !== "disabled" &&
                        (varHub?.hardwareModel === "xv60" ||
                          varHub?.hardwareModel === "xv96") &&
                        secondaryNetworkOption.description ===
                          "Static IP Address" ? (
                          <>
                            <Root>
                              <TextContainer>
                                <Label>
                                  Enter Static IP Address
                                  <span className="text-danger">*</span>
                                </Label>
                                {staticIpError ? (
                                  <ErrorMessage>
                                    <Icon name="attention" />
                                    <ErrorMessageText>
                                      {staticIpError}
                                    </ErrorMessageText>
                                  </ErrorMessage>
                                ) : (
                                  `\n`
                                )}
                              </TextContainer>
                              <TextInput
                                type="text"
                                name="StaticIP"
                                pattern={ipPattern}
                                value={varStaticIp}
                                ref={staticIpRef}
                                maxLength={15}
                                style={{
                                  maxWidth: "20rem",
                                  borderColor: staticIpError ? "red" : "",
                                }}
                                className="form-control"
                                onChange={({ target: { value } }) =>
                                  setVarStaticIp(value)
                                }
                                required
                              />
                            </Root>
                            <Root>
                              <TextContainer>
                                <Label>
                                  Enter Subnet Mask
                                  <span className="text-danger">*</span>
                                </Label>
                                {subnetMaskError ? (
                                  <ErrorMessage>
                                    <Icon name="attention" />
                                    <ErrorMessageText>
                                      {subnetMaskError}
                                    </ErrorMessageText>
                                  </ErrorMessage>
                                ) : (
                                  `\n`
                                )}
                              </TextContainer>
                              <TextInput
                                type="text"
                                name="SubnetMask"
                                pattern={ipPattern}
                                value={varSubnetMask}
                                ref={subnetMaskRef}
                                maxLength={15}
                                style={{
                                  maxWidth: "20rem",
                                  borderColor: subnetMaskError ? "red" : "",
                                }}
                                onChange={({ target: { value } }) =>
                                  setVarSubnetMask(value)
                                }
                                required
                              />
                            </Root>
                          </>
                        ) : null}
                      </OptionsTextContainer>
                    </PanelCommunicationOptions>
                  </Suspense>
                </LeftSettings>
              ) : null}

              <RightSettings leftSideEnabled={canUseIntegration}>
                <HubHeader>
                  <SettingsTitles style={{ marginRight: "2rem" }}>
                    {varHub?.hardwareModelDisplayName} Information
                  </SettingsTitles>
                  <>
                    {varHub?.panelConnectionEnabled ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {data && varHub ? (
                          <PanelConnectionBadge varHub={varHub} />
                        ) : null}
                        <div style={{ marginLeft: "2.5rem" }}>
                          <RetryPanelConnectionEnableButton
                            enablePanelIntegration={enablePanelIntegration}
                            varHub={varHub}
                            isUpdatingPanelIntegration={
                              isUpdatingPanelIntegration
                            }
                            retryingPanelConnection={retryingPanelConnection}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                </HubHeader>
                {!varHub?.isOnline ? (
                  <LastUpdatedStatus>{`Last Updated: ${
                    varHub?.lastUpdatedAt
                      ? new Date(varHub?.lastUpdatedAt)
                      : `Unknown`
                  }`}</LastUpdatedStatus>
                ) : null}
                <SettingsOption
                  label="MAC Address"
                  type="text"
                  value={varHub?.macAddress}
                />
                <SettingsOption
                  label="Software Version"
                  type="text"
                  value={varHub?.softwareVersion}
                />
                <SettingsOption
                  label="OS Version"
                  type="text"
                  value={varHub?.osVersion}
                />
                <SettingsOption
                  label="AI Model Version"
                  type="text"
                  value={varHub?.aiModelVersion}
                />
                <SettingsOption
                  label="Private IP Address"
                  type="text"
                  value={varHub?.privateIp}
                  className="mar-b-16"
                />
                {varHub?.secondaryNetworkType === "disabled" &&
                (varHub?.hardwareModel === "xv60" ||
                  varHub?.hardwareModel === "xv96") ? (
                  <>
                    <SettingsOption
                      label="Secondary Network"
                      type="text"
                      value="Disabled"
                      radialInfo={`If your private network has a DHCP Server\u000Ado not assign a Static IP Address, or ensure\u000Aa Static IP Address is reserved.`}
                    />
                    <OptionsDescription>
                      {
                        "To enable the secondary network interface connect a network cable to the second network switch. Wait up to 2 minutes and refresh this page to enable secondary network options."
                      }
                    </OptionsDescription>
                  </>
                ) : null}
                {showSubnetMaskModal ? (
                  <>
                    <Modal>
                      <div className="items-center justify-center status-modal modal-header bb0">
                        <StyledH3 className="modal-title">
                          Configure IMMIX monitoring
                        </StyledH3>
                        <BlockText>
                          Enable IMMIX below, then set the site up in your IMMIX
                          portal. Once set up in IMMIX, return to this form to
                          enter the required information below and assign
                          cameras.
                        </BlockText>
                        <BlockText>
                          <a
                            href="https://xvgateway.dmp.support/xv/"
                            className="da-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                          </a>
                        </BlockText>
                        <ImmixRoot>
                          <SettingsOption
                            label="Enable IMMIX Monitoring"
                            type="label"
                          />
                          <Switch
                            label="Configure IMMIX"
                            checked={showImmixButton}
                            onChange={handleSwitchChange}
                            disabled={isUpdating || isUpdatingImmix}
                          />
                        </ImmixRoot>
                        {showImmixButton ? (
                          <>
                            <SettingsOption
                              label="Device ID"
                              type="text"
                              value={deviceId}
                              disabled={isUpdating || isUpdatingImmix}
                              setInputChange={setDeviceId}
                              required={true}
                              errorAlert={deviceIdError !== ""}
                              errorMessage={deviceIdError}
                              width="100%"
                            />
                            <SettingsOption
                              label="SMTP Server"
                              type="text"
                              value={smtpServer}
                              disabled={isUpdating || isUpdatingImmix}
                              setInputChange={setSmtpServer}
                              required={true}
                              errorAlert={smtpServerError !== ""}
                              errorMessage={smtpServerError}
                              width="100%"
                            />
                            <SettingsOption
                              label="SMTP Port"
                              type="text"
                              value={smtpPort}
                              disabled={isUpdating || isUpdatingImmix}
                              setInputChange={setSmtpPort}
                              required={true}
                              errorAlert={smtpPortError !== ""}
                              errorMessage={smtpPortError}
                              width="100%"
                            />
                            <SettingsOption
                              label="IMMIX SMTP Username"
                              type="text"
                              value={user}
                              disabled={isUpdating || isUpdatingImmix}
                              setInputChange={setUser}
                              errorAlert={userError !== ""}
                              errorMessage={userError}
                              width="100%"
                            />
                            <SettingsOption
                              label="IMMIX SMTP Password"
                              type="text"
                              value={password}
                              disabled={isUpdating || isUpdatingImmix}
                              setInputChange={(newValue) => {
                                setPassword(newValue);
                                handleInitalPassword();
                              }}
                              errorAlert={passwordError !== ""}
                              errorMessage={passwordError}
                              width="100%"
                            />
                            <Root>
                              <TextContainer>
                                <Label>Send Auto-Suppressed Alerts</Label>
                              </TextContainer>
                              <CheckBox
                                type="checkbox"
                                checked={sendAutoSuppression} // Set checkbox checked status based on state variable
                                onChange={handleCheckboxChange} // Call handleCheckboxChange function on checkbox change
                              />
                            </Root>
                            <ImmixCameraRoot>
                              <TextContainer>
                                <Label>Cameras</Label>
                              </TextContainer>
                              <StyledSelect
                                onChange={handleCameraDropdown}
                                className="form-control"
                                value={
                                  immixCamera ? immixCamera.cameraName : ""
                                }
                                disabled={
                                  isUpdating ||
                                  isUpdatingImmix ||
                                  cameraList.length < 1
                                }
                              >
                                {cameraList.map((option, index) => (
                                  <option key={index} value={option.cameraName}>
                                    {`${option.cameraName} (${option.ipAddress})`}
                                  </option>
                                ))}
                              </StyledSelect>
                              <IdContainer>
                                <Label>ID:</Label>
                              </IdContainer>
                              <ImmixCameraNumberInput
                                disabled={
                                  isUpdating ||
                                  isUpdatingImmix ||
                                  cameraList.length < 1
                                }
                                value={immixCameraInputId}
                                onChange={handleCameraInputId}
                              />
                              <button
                                className="btn btn-dmp btn-sm"
                                onClick={handleImmixCameraAdd}
                                disabled={
                                  isUpdating ||
                                  isUpdatingImmix ||
                                  cameraList.length < 1 ||
                                  !(
                                    immixCameraInputId !== null &&
                                    immixCameraInputId !== "" &&
                                    !isNaN(parseInt(immixCameraInputId))
                                  )
                                }
                              >
                                Add
                              </button>
                            </ImmixCameraRoot>
                            <ImmixCameraHeader>
                              <NameHeader>Name</NameHeader>
                              <IPHeader>IP Address</IPHeader>
                              <IDHeader>IMMIX ID</IDHeader>
                            </ImmixCameraHeader>
                            <ImmixCameraTable>
                              {configuredCameraList.map((camera, index) => (
                                <ImmixCameraDetails key={index} index={index}>
                                  <NameContainer>
                                    {camera.cameraObj.cameraName}
                                  </NameContainer>
                                  <IPContainer>
                                    {camera.cameraObj.ipAddress}
                                  </IPContainer>
                                  <IdContainer2>{camera.id}</IdContainer2>
                                  <ButtonContainer>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      disabled={isUpdating || isUpdatingImmix}
                                      onClick={() =>
                                        handleDeleteCam(
                                          camera.cameraObj.camectCamId
                                        )
                                      }
                                    >
                                      Remove
                                    </button>
                                  </ButtonContainer>
                                </ImmixCameraDetails>
                              ))}
                            </ImmixCameraTable>
                          </>
                        ) : null}
                      </div>

                      <ModalFooter>
                        <button
                          className="btn btn-dmp btn-sm"
                          onClick={handleModalSave}
                          disabled={
                            isUpdating ||
                            isUpdatingImmix ||
                            !gatewayIpAddress ||
                            !panelIpAddress
                          }
                        >
                          {isUpdating || isUpdatingImmix ? `Saving...` : `Save`}
                        </button>
                        <button
                          type="button"
                          className={`btn btn-sm btn-default`}
                          onClick={handleCancel}
                          disabled={isUpdating || isUpdatingImmix}
                        >
                          Cancel
                        </button>
                      </ModalFooter>
                    </Modal>
                  </>
                ) : null}
                {varHub?.panelConnectionEnabled ? (
                  <>
                    <SettingsTitles
                      style={{ margin: "1.6rem 0 2.4rem 0" }}
                    >{`Panel Integration Information`}</SettingsTitles>
                    <Flex.Row align="center" justify="flex-start">
                      <SettingsOption
                        label="Auto-Discover Panel"
                        type="label"
                        radialInfo="Disabling Panel Auto-Discovery will stop the XV Gateway from auto-scanning and locating any DMP Panel on the network. You will be required to manually input the XV and Panel IP addresses in order for them to communicate."
                      />

                      <Switch
                        label=""
                        checked={isAutoDiscoveryEnabled ?? true}
                        onChange={handleAutoDiscoverySwitchToggled}
                        disabled={rolePreventsToggleAutoDiscovery()}
                      />
                    </Flex.Row>

                    <SettingsOption
                      label="XV Gateway IP Address"
                      type="text"
                      disabled={isDisabled ?? true}
                      value={gatewayIpAddress}
                      setInputChange={setGatewayIpAddress}
                      radialInfo="The Panel uses this address to communicate to the XV Gateway. It should match the XV Gateway IP Address listed above. Ensure this address is configured with a DHCP reservation."
                      errorAlert={xvGatewayIpAddressError !== ""}
                      errorMessage={xvGatewayIpAddressError}
                    />

                    <SettingsOption
                      label="Panel IP Address"
                      type="text"
                      disabled={isDisabled ?? true}
                      value={panelIpAddress}
                      setInputChange={setPanelIpAddress}
                      radialInfo="The XV Gateway uses this address to communicate to the panel. It should match the panel's IP Address listed in Network Options. Ensure this address is configured statically or with a DHCP reservation."
                      errorAlert={panelIpAddressError !== ""}
                      errorMessage={panelIpAddressError}
                    />

                    {/* End new fields */}

                    <SettingsOption
                      label="Version"
                      type="text"
                      value={varHub?.panelIntegrationVersion}
                    />
                    <SettingsOption
                      label="XV Gateway Uptime"
                      type="text"
                      radialInfo="This represents the time the XV Gateway has been working and online."
                      value={varHub?.panelIntegrationSystemUptime}
                    />

                    <SettingsOption
                      label="Panel-Integration Uptime"
                      type="text"
                      radialInfo="This represents the time software integration from the Gateway to the panel has been working."
                      value={varHub?.panelIntegrationUptime}
                    />
                    <SettingsOption
                      label="Last XV Gateway to Panel Message"
                      type="text"
                      value={varHub?.panelIntegrationLastInbound}
                    />
                    <SettingsOption
                      label="Last Panel to XV Gateway Message"
                      type="text"
                      value={varHub?.panelIntegrationLastOutbound}
                    />
                    {(canViewCamectTroubleshootingTools() as boolean) ? (
                      <Flex.Row>
                        <DownloadLogsButton
                          spacerRight
                          systemId={UserService.control_system_id}
                          hubId={state.params.var_hub_id}
                          hubName={varHub?.hubName}
                          userService={UserService}
                        />
                        <ViewConfigurationButton
                          spacerRight
                          systemId={UserService.control_system_id}
                          hubId={state.params.var_hub_id}
                          hubName={varHub?.hubName}
                          userService={UserService}
                        />
                        <WsDiscoveryProbeButton
                          spacerRight
                          systemId={UserService.control_system_id}
                          hubId={state.params.var_hub_id}
                          hubName={varHub?.hubName}
                          isWsDiscoveryProbeModalOpen={
                            isWsDiscoveryProbeModalOpen
                          }
                          setIsWsDiscoveryProbeModalOpen={
                            setIsWsDiscoveryProbeModalOpen
                          }
                        />
                        <PingPanelButton
                          systemId={UserService.control_system_id}
                          hubId={state.params.var_hub_id}
                          hubName={varHub?.hubName}
                          setIsPingPanelModalOpen={setIsPingPanelModalOpen}
                          isPingPanelModalOpen={isPingPanelModalOpen}
                        />
                      </Flex.Row>
                    ) : null}
                  </>
                ) : null}
              </RightSettings>
            </MainSettingsContainer>
          </MainEdit>
        </fieldset>
      </Grid>
      {confirmingDelete ? (
        <DeleteConfirmModal
          actionPending={isDeleting}
          onConfirm={() => {
            if (!isDeleting) {
              deleteVarHub({
                variables: {
                  hubId: state.params.var_hub_id,
                  systemId: idAsString(
                    toControlSystemId(UserService.control_system_id)
                  ),
                },
                onCompleted: (data) => {
                  if (
                    data.deleteVarHub?.__typename ===
                    "DeleteVarHubSuccessPayload"
                  ) {
                    showAlert({
                      type: "success",
                      text: `Successfully Deleted: ${
                        varHub?.hubName ?? `XV Gateway`
                      }`,
                      suppressTitleCasing: true,
                    });
                    goBackToControlSystem();
                  } else {
                    showAlert({
                      type: "error",
                      text: `Error Deleting: ${
                        varHub?.hubName ?? `XV Gateway`
                      }`,
                      suppressTitleCasing: true,
                    });
                  }
                },
                onError: (error) => {
                  showAlert({
                    type: "error",
                    text: error.message,
                  });
                },
              });
            }
          }}
          onCancel={() => {
            setConfirmingDelete(false);
          }}
        >
          Are you sure you want to delete this XV Gateway?
        </DeleteConfirmModal>
      ) : null}
      {confirmingRestart ? (
        <GenericConfirmModal
          header="Restart XV Gateway"
          actionPending={isRestarting}
          confirmText="Restart"
          cancelText="Cancel"
          pendingText="Restarting..."
          onConfirm={() => {
            if (!isRestarting) {
              restartVarHub({
                variables: {
                  hubId: state.params.var_hub_id,
                  systemId: idAsString(
                    toControlSystemId(UserService.control_system_id)
                  ),
                },
                onCompleted: (data) => {
                  if (
                    data.restartVarHub?.__typename ===
                    "RestartVarHubSuccessPayload"
                  ) {
                    commitLocalUpdate(relayEnvironment, (store) => {
                      const varHubRecord = store.get(varHubId);
                      if (varHubRecord) {
                        varHubRecord.setValue(false, "isOnline");
                        varHubRecord.setValue(
                          VarHubPanelConnectionStatus.UNKNOWN,
                          "panelConnectionStatus"
                        );
                      }
                    });
                    showAlert({
                      type: "success",
                      text: `Successfully Restarted: ${
                        varHub?.hubName ?? `XV Gateway`
                      }`,
                      suppressTitleCasing: true,
                    });
                    setConfirmingRestart(false);
                  } else {
                    showAlert({
                      type: "error",
                      text: `Error Restarting: ${
                        varHub?.hubName ?? `XV Gateway`
                      }`,
                      suppressTitleCasing: true,
                    });
                  }
                },
                onError: (error) => {
                  showAlert({
                    type: "error",
                    text: error.message,
                  });
                },
              });
            }
          }}
          onCancel={() => {
            setConfirmingRestart(false);
          }}
        >
          Are you sure you want to restart this XV Gateway? It may take a few
          minutes for the XV Gateway to come back online.
        </GenericConfirmModal>
      ) : null}
    </>
  );
};

const RetryPanelConnectionEnableButton = (props: {
  varHub: RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub$key;
  isUpdatingPanelIntegration: boolean;
  retryingPanelConnection: React.MutableRefObject<boolean>;
  enablePanelIntegration: (enabled: boolean) => Promise<void>;
}) => {
  const data = useFragment(
    graphql`
      fragment RecorderEditRestartVarHub_RetryPanelConnectionEnableButton_varHub on VarHub {
        panelConnectionStatus
        isOnline
      }
    `,
    props.varHub
  );

  return data?.panelConnectionStatus ===
    VarHubPanelConnectionStatus.DISCONNECTED ? (
    <div className="row">
      <button
        className="btn btn-dmp btn-sm btn-border-inverse"
        disabled={!data?.isOnline || props.isUpdatingPanelIntegration}
        onClick={() => {
          props.retryingPanelConnection.current = true;
          props.enablePanelIntegration(true).then(() => {
            props.retryingPanelConnection.current = false;
          });
        }}
      >
        {props.isUpdatingPanelIntegration &&
        props.retryingPanelConnection.current
          ? `Retrying`
          : `Retry`}
      </button>
    </div>
  ) : null;
};

export function dangerouslyAddToApp() {
  App.component(
    "recorderEdit",
    react2angular(
      RecorderEditRoot,
      [],
      [
        "$rootScope",
        "$scope",
        "$state",
        "UserService",
        "RelayService",
        "CAMECT_TIME_ZONES",
      ]
    )
  );
}
const HubHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
`;
const NameContainer = styled.span`
  grid-column: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledSelect = styled.select`
  min-width: 20rem;
`;
const NameHeader = styled.span`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IdContainer2 = styled.span`
  grid-column: 3 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IPContainer = styled.span`
  grid-column: 2 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IDHeader = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IPHeader = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface ImmixCameraDetailsProps {
  index: number;
}
const ImmixCameraHeader = styled.div`
  border-radius: 0.5rem;
  background-color: white;
  grid-column: 1 / 5;
  display: grid;
  grid-template-columns: [first] 2fr 2fr 1fr 1fr;
  grid-auto-rows: minmax(5rem, max-content);
  align-items: center;
`;
const ImmixCameraDetails = styled.div<ImmixCameraDetailsProps>`
  border-radius: 0.5rem;
  background-color: ${(props) => (props.index % 2 === 0 ? "#f5f7f9" : "white")};
  grid-column: 1 / 5;
  display: grid;
  grid-template-columns: [first] 2fr 2fr 1fr 1fr;
  grid-auto-rows: minmax(5rem, max-content);
  align-items: center;
`;
const ImmixCameraTable = styled.div`
  max-height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
`;
const ImmixCameraRoot = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem;
`;
const ImmixCameraNumberInput = styled(TextInput)`
  width: 8rem !important;
`;
const IdContainer = styled.div`
  padding-left: 2rem;
  min-width: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ButtonContainer = styled.div`
  grid-column: 4 / 5;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ErrorMessageText = styled.div`
  color: black;
  margin-left: 0.4rem;
`;

const Immix = styled.div`
  margin: 0 0 2rem 0;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`;
const SwitchContainer = styled.div`
  min-width: 10rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 0rem;
  gap-x: 4rem;
  width: 100%;
`;
const ImmixButton = styled.button`
  width: 24rem;
`;
const TestConButton = styled.button`
  width: 13rem;
`;
const AbleButton = styled.button`
  width: 10rem;
`;
const Root = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem;
`;
const ImmixRoot = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 2rem;
`;
const TextContainer = styled.div`
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0rem;
`;
const Label = styled.span`
  font-weight: bold;
  width: 15rem;
`;
const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  gap: 1rem;
  padding-right: 2rem;
`;
const CheckBox = styled.input`
  &:checked {
    accent-color: var(--color-info-600) !important;
  }import ViewConfiguration from './ViewConfiguration';

  width: 2.3rem;
  height: 2.3rem;
`;

const StyledH3 = styled.h3`
  margin-bottom: 1.2rem;
`;
const BlockText = styled.div`
  color: black;
  font-size: 1.4rem;
  margin-bottom: 0.375rem;
`;
const ErrorMessage = styled.span`
  display: flex;
  color: red;
  position: absolute;
  margin-top: 5rem;
  justify-content: flex-end;
`;
const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 3fr 3fr;
  grid-template-columns: 1fr 1f 1fr 2fr;
`;
const Header = styled.div`
  grid-column-start: 1;
  grid-column-end: end;
`;
const MainEdit = styled.div`
  grid-column-start: 1;
  grid-column-end: end;
  grid-row-start: 1;
  grid-row-end: end;
  background-color: white;
  border-radius: 0.4rem;
  padding: 2rem;
`;
const InnerHeader = styled.div`
  grid-column-start: 1;
  grid-column-end: end;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  @media (min-width: 90rem) {
    flex-direction: row;
  }
`;
const InputLabel = styled.span`
  white-space: nowrap;
  width: 10rem;
`;
const InputContainer = styled.span`
  display: flex;
  width: 50ch;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 2rem;
  @media (min-width: 90rem) {
    margin-bottom: 0;
  }
`;
const MainSettingsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 90rem) {
    flex-direction: row;
  }
`;
const HeadingWithGreyLine = styled.div`
  padding-top: 1.5rem;
  border-bottom: 0.1rem solid lightgray;
  grid-column-start: 1;
  grid-column-end: 2;
  color: #5aa4c4;
  font-size: 2.5rem;
  font-weight: bold;
`;
const RightSettings = styled.div<{ leftSideEnabled: boolean }>`
  padding: 1rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (min-width: 90rem) {
    margin-left: 1rem;
    width: 45%;
  }
`;
const LeftSettings = styled.span`
  padding: 1rem;
  display: flex;
  width: 99%;
  flex-direction: column;
  border-bottom: 0.1rem solid lightgray;
  @media (min-width: 90rem) {
    width: 55%;
    border-bottom: 0;
    border-right: 0.1rem solid lightgray;
  }
`;
const SettingsTitles = styled.span`
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const SettingsDropdown = styled.span`
  width: 45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10rem;
`;

const LastUpdatedStatus = styled.span``;

const PanelCommunicationOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem;
`;

const OptionsTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ImmixLabel = styled.span`
  font-weight: bold;
`;
const RadialInfo = styled.span`
  font-weight: bold;
  font-size: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const ModalRadialInfo = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const OptionsLabel = styled.span`
  font-weight: bold;
  align-items: center;
  display: flex;
  width: 100%;
`;
const OptionsDescription = styled.span`
  margin: 0 0 3rem 2rem;
  width: 75%;
`;
const OptionsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const IntegrationsSettingsContainer = styled.div`
  padding-left: 2rem;
`;
