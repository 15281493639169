import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { ZoneMessage, ZoneType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationArmedShortMessageField_zone$key } from "./__generated__/ZoneInformationArmedShortMessageField_zone.graphql";

export const zoneInformationArmedShortMessageFieldId = (number: string) =>
  `zone-information-armed-short-message-${number}`;

function ZoneInformationArmedShortMessageField() {
  const [{ number, armedShortActionMessage, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationArmedShortMessageField_zone$key>(
      graphql`
        fragment ZoneInformationArmedShortMessageField_zone on Zone {
          number
          type
          armedShortActionMessage
        }
      `
    );

  const fieldId = zoneInformationArmedShortMessageFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const { isXr, isXf } = resolvePanelType(hardwareModel);
  const disabled = [ZoneType.ARMING, ZoneType.TAMPER].includes(
    type as ZoneType
  );
  const alarmEnabled = ZoneType.ARMING !== type;
  const troubleEnabled = ZoneType.ARMING !== type;
  const localEnabled = ZoneType.ARMING !== type;
  const cancelAmbushEnabled = isXr;
  const doorProppedOpenEnabled = isXr;
  const sensorResetEnabled = isXr;
  const label = isXf ? "Short Message" : "Armed Short Message";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Defines action taken by the System when this Zone is in an short condition while the Area is Armed. Transmits Alarm or Trouble to the Receiver."
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={armedShortActionMessage ?? undefined}
        disabled={disabled || isXf}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "armedShortActionMessage");
          });
        }}
      >
        <Select.Option value={ZoneMessage.NONE}>None</Select.Option>
        {alarmEnabled ? (
          <Select.Option value={ZoneMessage.ALARM}>Alarm</Select.Option>
        ) : null}
        {troubleEnabled ? (
          <Select.Option value={ZoneMessage.TROUBLE}>Trouble</Select.Option>
        ) : null}
        {localEnabled ? (
          <Select.Option value={ZoneMessage.LOCAL}>Local</Select.Option>
        ) : null}
        {cancelAmbushEnabled ? (
          <Select.Option value={ZoneMessage.CANCEL_AMBUSH}>
            Cancel Ambush
          </Select.Option>
        ) : null}
        {doorProppedOpenEnabled ? (
          <Select.Option value={ZoneMessage.DOOR_PROPPED_OPEN}>
            Door Propped Open
          </Select.Option>
        ) : null}
        {sensorResetEnabled ? (
          <Select.Option value={ZoneMessage.SENSOR_RESET}>
            Sensor Reset
          </Select.Option>
        ) : null}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationArmedShortMessageField;
