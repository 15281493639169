import graphql from "babel-plugin-relay/macro";
import DefaultAdvancedFields from "components/AdvancedFields";
import CommPathNumberField from "components/FullProgramming/common/CommPathFields/CommPathNumberField";
import * as React from "react";
import { useFragment } from "react-relay";
import styled from "styled-components/macro";
import CommPathAlarmReportsField from "../../common/CommPathFields/CommPathAlarmReportsField";
import CommPathCellApnField from "../../common/CommPathFields/CommPathCellApnField";
import CommPathCheckInMinutesField from "../../common/CommPathFields/CommPathCheckInMinutesField";
import CommPathCommTypeField from "../../common/CommPathFields/CommPathCommTypeField";
import { CommPathContextProvider } from "../../common/CommPathFields/CommPathContext";
import CommPathDoorAccessReportsField from "../../common/CommPathFields/CommPathDoorAccessReportsField";
import CommPathEncryptionField from "../../common/CommPathFields/CommPathEncryptionField";
import CommPathFailTestHoursField from "../../common/CommPathFields/CommPathFailTestHoursField";
import CommPathFailTimeField from "../../common/CommPathFields/CommPathFailTimeField";
import CommPathOpenCloseUserReportsField from "../../common/CommPathFields/CommPathOpenCloseUserReportsField";
import CommPathReceiverIpAddressField from "../../common/CommPathFields/CommPathReceiverIpAddressField";
import CommPathReceiverPortField from "../../common/CommPathFields/CommPathReceiverPortField";
import CommPathSendFailField from "../../common/CommPathFields/CommPathSendFailField";
import CommPathSendPathField from "../../common/CommPathFields/CommPathSendPathField";
import CommPathSupervisoryTroubleReportsField from "../../common/CommPathFields/CommPathSupervisoryTroubleReportsField";
import CommPathTestFrequencyDayField from "../../common/CommPathFields/CommPathTestFrequencyDayField";
import CommPathTestFrequencyNumberField from "../../common/CommPathFields/CommPathTestFrequencyNumberField";
import CommPathTestFrequencyUnitField from "../../common/CommPathFields/CommPathTestFrequencyUnitField";
import CommPathTestReportField from "../../common/CommPathFields/CommPathTestReportField";
import CommPathTestTimeField from "../../common/CommPathFields/CommPathTestTimeField";
import CommPathTypeField from "../../common/CommPathFields/CommPathTypeField";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import { XT75CommPathFields_communicationPath$key } from "./__generated__/XT75CommPathFields_communicationPath.graphql";

const AdvancedFields = styled(DefaultAdvancedFields)`
  margin-top: var(--measure-3x);
`;

function XT75CommPathFields(props: {
  commPath: XT75CommPathFields_communicationPath$key;
}) {
  const data = useFragment(
    graphql`
      fragment XT75CommPathFields_communicationPath on CommunicationPath {
        id
        ...CommPathContext_communicationPath
        ...CommPathNumberField_communicationPath
        ...CommPathCommTypeField_communicationPath
        ...CommPathTypeField_communicationPath
        ...CommPathTestReportField_communicationPath
        ...CommPathTestFrequencyNumberField_communicationPath
        ...CommPathTestFrequencyUnitField_communicationPath
        ...CommPathTestFrequencyDayField_communicationPath
        ...CommPathTestTimeField_communicationPath
        ...CommPathUseCheckInField_communicationPath
        ...CommPathCheckInMinutesField_communicationPath
        ...CommPathFailTimeField_communicationPath
        ...CommPathReceiverIpAddressField_communicationPath
        ...CommPathReceiverPortField_communicationPath
        ...CommPathEncryptionField_communicationPath
        ...CommPathFailTestHoursField_communicationPath
        ...CommPathCellApnField_communicationPath
        ...CommPathRetryTimeField_communicationPath
        ...CommPathAlarmReportsField_communicationPath
        ...CommPathSupervisoryTroubleReportsField_communicationPath
        ...CommPathOpenCloseUserReportsField_communicationPath
        ...CommPathDoorAccessReportsField_communicationPath
        ...CommPathSendFailField_communicationPath
        ...CommPathSendPathField_communicationPath
      }
    `,
    props.commPath
  );

  return (
    <CommPathContextProvider commPath={data}>
      <ProgrammingConceptForm.Fields>
        <CommPathNumberField />
        <CommPathCommTypeField />
        <CommPathTypeField />
        <CommPathTestReportField />
        <CommPathTestFrequencyNumberField />
        <CommPathTestFrequencyUnitField />
        <CommPathTestFrequencyDayField />
        <CommPathTestTimeField />
        <CommPathCheckInMinutesField />
        <CommPathFailTimeField />
        <CommPathReceiverIpAddressField />
        <CommPathReceiverPortField />
        <CommPathEncryptionField />
      </ProgrammingConceptForm.Fields>
      <AdvancedFields>
        <ProgrammingConceptForm.Fields>
          <CommPathFailTestHoursField />
          <CommPathCellApnField />
          <CommPathAlarmReportsField />
          <CommPathSupervisoryTroubleReportsField />
          <CommPathOpenCloseUserReportsField />
          <CommPathDoorAccessReportsField />
          <CommPathSendFailField />
          <CommPathSendPathField />
        </ProgrammingConceptForm.Fields>
      </AdvancedFields>
    </CommPathContextProvider>
  );
}

export default XT75CommPathFields;
