import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { ZoneMessage, ZoneType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationArmedOpenMessageField_zone$key } from "./__generated__/ZoneInformationArmedOpenMessageField_zone.graphql";

export const zoneInformationArmedOpenMessageFieldId = (number: string) =>
  `zone-information-armed-open-message-${number}`;

function ZoneInformationArmedOpenMessageField() {
  const [{ number, armedOpenActionMessage, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationArmedOpenMessageField_zone$key>(
      graphql`
        fragment ZoneInformationArmedOpenMessageField_zone on Zone {
          number
          type
          armedOpenActionMessage
        }
      `
    );

  const fieldId = zoneInformationArmedOpenMessageFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const { isXr, isXf } = resolvePanelType(hardwareModel);
  const disabled = [ZoneType.ARMING, ZoneType.TAMPER].includes(
    type as ZoneType
  );
  const alarmEnabled = ZoneType.ARMING !== type;
  const troubleEnabled = ZoneType.ARMING !== type;
  const localEnabled = ZoneType.ARMING !== type;
  const cancelAmbushEnabled = isXr;
  const doorProppedOpenEnabled = isXr;
  const sensorResetEnabled = isXr;
  const label = isXf ? "Open Message" : "Armed Open Message";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Defines action taken by the System when this Zone is in an open condition while the Area is Armed. Transmits Alarm or Trouble to the Receiver."
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={armedOpenActionMessage ?? undefined}
        disabled={disabled || isXf}
        required
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "armedOpenActionMessage");
          });
        }}
      >
        <Select.Option value={ZoneMessage.NONE}>None</Select.Option>
        {alarmEnabled && (
          <Select.Option value={ZoneMessage.ALARM}>Alarm</Select.Option>
        )}
        {troubleEnabled && (
          <Select.Option value={ZoneMessage.TROUBLE}>Trouble</Select.Option>
        )}
        {localEnabled && (
          <Select.Option value={ZoneMessage.LOCAL}>Local</Select.Option>
        )}
        {cancelAmbushEnabled && (
          <Select.Option value={ZoneMessage.CANCEL_AMBUSH}>
            Cancel Ambush
          </Select.Option>
        )}
        {doorProppedOpenEnabled && (
          <Select.Option value={ZoneMessage.DOOR_PROPPED_OPEN}>
            Door Propped Open
          </Select.Option>
        )}
        {sensorResetEnabled && (
          <Select.Option value={ZoneMessage.SENSOR_RESET}>
            Sensor Reset
          </Select.Option>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationArmedOpenMessageField;
