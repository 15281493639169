App.directive("daCustomRoles", [
  "CustomRolesService",
  "UserService",
  function (CustomRolesService, UserService) {
    return {
      restrict: "E",

      require: "^form",

      scope: {
        role: "=",
        type: "=",
        disableFields: "=",
        showLabel: "=",
        tags: "=",
      },

      templateUrl: function (element, scope) {
        switch (scope.type) {
          case "technician":
            return "/app/personnel/custom-roles/da-technician-custom-roles-tpl.html";

          case "custom":
            return "/app/personnel/custom-roles/da-custom-roles-tpl.html";
        }
      },

      link: function (scope, element, attrs, formCtrl) {
        scope.UserService = UserService;
        scope.ipWhitelistEnabled = UserService.enabledIpWhitelist();
        var pristineRole = {};
        scope.$watch("role.id", function (newValue, oldValue) {
          if (angular.isDefined(newValue)) {
            pristineRole = {};
            angular.merge(pristineRole, scope.role);
            scope.validateDaySelection();
            if (scope.disableFields) {
              scope.displayStartTime = undoTimeZoneOffset(
                scope.role.start_time
              );
              scope.displayEndTime = undoTimeZoneOffset(scope.role.end_time);
            }
          }
        });

        scope.updateCachedTime = function () {
          pristineRole.start_time = scope.role.start_time;
          pristineRole.end_time = scope.role.end_time;
        };

        scope.processAllDayCheck = function () {
          updateTime("start_time", "DAY_START_TIME");
          updateTime("end_time", "DAY_END_TIME");
        };

        scope.processEveryDayCheck = function () {
          for (let day of [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
          ]) {
            scope.role[day] = scope.role.every_day;
          }
          scope.validateDaySelection();
        };

        scope.processLimitEntity = () => {
          if (!scope.role.limit_entity) {
            scope.role.view_all_customers = false;
          }
        };

        function setEveryDayFlag() {
          if (
            scope.role.sunday &&
            scope.role.monday &&
            scope.role.tuesday &&
            scope.role.wednesday &&
            scope.role.thursday &&
            scope.role.friday &&
            scope.role.saturday
          ) {
            scope.role.every_day = true;
          }
        }

        scope.validateDaySelection = function () {
          if (
            DoesNestedPropertyExist(formCtrl, "customRoleForm.dayBlockForm")
          ) {
            if (daySelectionValid(scope.role)) {
              formCtrl.customRoleForm.dayBlockForm.$setValidity(
                "noSelection",
                true
              );
            } else {
              formCtrl.customRoleForm.dayBlockForm.$setValidity(
                "noSelection",
                false
              );
            }
          }
        };

        scope.dropDownOptionsTechApp =
          CustomRolesService.dropDownOptionsTechApp;

        scope.dropDownOptionsCustom = CustomRolesService.dropDownOptionsCustom;

        function updateTime(timeSlot, limit) {
          var guiLimit = CustomRolesService.getGUIDateString(
            CustomRolesService[limit]
          );
          scope.role[timeSlot] = scope.role.all_day
            ? guiLimit
            : pristineRole.all_day && pristineRole[timeSlot] === guiLimit
            ? ""
            : pristineRole[timeSlot];
        }

        function undoTimeZoneOffset(dateString) {
          var date = new Date(dateString);
          var timeZoneOffset = date.getTimezoneOffset() * 60 * 1000; // user's offset time, in minutes
          return new Date(date.getTime() + timeZoneOffset);
        }

        function daySelectionValid(role) {
          return (
            role.sunday ||
            role.monday ||
            role.tuesday ||
            role.wednesday ||
            role.thursday ||
            role.friday ||
            role.saturday
          );
        }

        function init() {
          setEveryDayFlag();
          scope["timeZones"] = [];
          CustomRolesService.getTimeZones(UserService.dealer_id).then(
            function (data) {
              angular.forEach(data, function (zone) {
                scope.timeZones.push(zone.name);
              });
            },
            function () {
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to get time zones",
              });
            }
          );
        }

        init();
      },
    };
  },
]);
