import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import * as React from "react";
import { ZoneType } from "securecom-graphql/client";
import { formatListOfNumbers } from "../../utils/format";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { resolveZoneFieldRules } from "./utils";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationPrewarnKeypadsField_zone$key } from "./__generated__/ZoneInformationPrewarnKeypadsField_zone.graphql";

export const zoneInformationPrewarnKeypadsFieldId = (number: string) =>
  `zone-information-prewarn-keypads-${number}`;

function ZoneInformationPrewarnKeypadsField() {
  const [{ number, prewarnKeypads, type }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationPrewarnKeypadsField_zone$key>(
      graphql`
        fragment ZoneInformationPrewarnKeypadsField_zone on Zone {
          number
          prewarnKeypads
          type
        }
      `
    );

  const fieldId = zoneInformationPrewarnKeypadsFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const disabled = ![ZoneType.EXIT, ZoneType.FINAL_EXIT].includes(
    type as ZoneType
  );
  const { PREWARN_KEYPADS: RULES } = resolveZoneFieldRules(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Prewarn Keypads"
      tooltip="List all Keypad addresses that should display 'ENTER CODE:' at the start of the Entry Delay when this Zone trips."
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        disabled={disabled}
        value={prewarnKeypads}
        pattern={RULES?.PATTERN}
        inlineHelp={RULES?.INLINE_HELP}
        validationMessage={RULES?.VALIDATION_MSG}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "prewarnKeypads");
          });
        }}
        onBlur={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "prewarnKeypads"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationPrewarnKeypadsField;
