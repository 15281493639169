import {
  systemIsScheduledForFutureInstall,
  systemIsSite,
  systemWillBeAutoProgrammed,
} from "../../../utils/control-systems";

/**
 * @ngdoc service
 * @name App.factory:Customer
 *
 * @description
 * API factory for Customer Information
 *
 */
App.factory("Customer", [
  "$q",
  "PROPS",
  "CustomersV2API",
  "DealerCustomersV2API",
  "UserService",
  "CustomerScoreAPI",
  "DashboardDataService",
  "ThirdPartyVideoService",
  "$rootScope",
  "SimManagementService",
  function (
    $q,
    PROPS,
    CustomersV2API,
    DealerCustomersV2API,
    UserService,
    CustomerScoreAPI,
    DashboardDataService,
    ThirdPartyVideoService,
    $rootScope,
    SimManagementService
  ) {
    var Customer = function (customerObject, dealerId) {
      angular.extend(this, {
        /**
         * Gets the customer
         * @param customerID
         * @returns {*}
         */
        get: function (customerID) {
          var deferred = $q.defer();
          var _this = this;
          CustomersV2API.show(
            { customer_id: customerID }, //params
            function (data) {
              //success
              _this.extendMe(data.customer);
              _this.isNew = false;
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        /**
         * Get the users for this customer
         * @param customerID
         * @returns {*}
         */
        getUsers: function (
          customer_id,
          pageNumber,
          pageSize,
          pageData,
          pageDefer
        ) {
          var custId = customer_id ? customer_id : this.customer_id;
          var auto_page = true; //'true' by default.  All records are appended and returned.
          var currentPage = typeof pageNumber !== "undefined" ? pageNumber : 1; //starts at 1 and is automatically incremented.
          //Anything over 99 only returns 99 items.  Default of 100 currently used to switch auto paging process.
          pageSize = typeof pageSize !== "undefined" ? pageSize : 100;
          pageData = typeof pageData !== "undefined" ? pageData : []; //passes the accumulated data to each recursion
          var deferred =
            typeof pageDefer !== "undefined" ? pageDefer : $q.defer(); //passes the original promise to each recursion
          var params = {
            customer_id: custId,
            page: currentPage,
            page_size: pageSize,
          }; //because additional params may be added, this is declared outside the API call
          var _this = this;
          if (pageSize < 100) {
            auto_page = false;
          }
          CustomersV2API.getUsers(
            params, //params
            function (data) {
              //success
              pageData = pageData.concat(data); //concatenates just the array part of the data
              if (data.length == pageSize && auto_page) {
                //if the array returned has the maximum number of elements, there could be more.
                deferred.notify({
                  job_uuid: "n/a",
                  status: "paging",
                  poll_count: 0,
                  page: currentPage,
                });
                _this
                  .getUsers(
                    customer_id,
                    ++currentPage,
                    pageSize,
                    pageData,
                    deferred
                  )
                  .then(
                    //recursively calls 'get' to retrieve next page of data
                    function () {
                      deferred.resolve(); // the current data is appended to the 'pageData' array, so we don't resolve anything here;
                    },
                    function (nextPageError) {
                      //If an error occurs, bail.
                      deferred.notify({
                        job_uuid: "n/a",
                        status: "error",
                        poll_count: 0,
                        page: currentPage,
                      });
                      deferred.reject(nextPageError);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              } else {
                deferred.notify({
                  job_uuid: "n/a",
                  status: "success",
                  poll_count: 0,
                  page: currentPage,
                });
                deferred.resolve(pageData);
              }
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //info
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        /**
         * Get the users for this customer
         * @param customerID
         * @returns {*}
         */
        getControlSystems: function (
          customer_id,
          pageNumber,
          pageSize,
          pageData,
          pageDefer
        ) {
          var custId = customer_id ? customer_id : this.customer_id;
          var auto_page = true; //'true' by default.  All records are appended and returned.
          var currentPage = typeof pageNumber !== "undefined" ? pageNumber : 1; //starts at 1 and is automatically incremented.
          //Anything over 99 only returns 99 items.  Default of 100 currently used to switch auto paging process.
          pageSize = typeof pageSize !== "undefined" ? pageSize : 100;
          pageData = typeof pageData !== "undefined" ? pageData : []; //passes the accumulated data to each recursion
          var deferred =
            typeof pageDefer !== "undefined" ? pageDefer : $q.defer(); //passes the original promise to each recursion
          var params = {
            customer_id: custId,
            page: currentPage,
            page_size: pageSize,
          }; //because additional params may be added, this is declared outside the API call
          var _this = this;
          if (pageSize < 100) {
            auto_page = false;
          }
          CustomersV2API.getControlSystems(
            params, //params
            function (data) {
              //success
              pageData = pageData.concat(data); //concatenates just the array part of the data
              if (data.length == pageSize && auto_page) {
                //if the array returned has the maximum number of elements, there could be more.
                deferred.notify({
                  job_uuid: "n/a",
                  status: "paging",
                  poll_count: 0,
                  page: currentPage,
                });
                _this
                  .getControlSystems(
                    customer_id,
                    ++currentPage,
                    pageSize,
                    pageData,
                    deferred
                  )
                  .then(
                    //recursively calls 'get' to retrieve next page of data
                    function () {
                      deferred.resolve(); // the current data is appended to the 'pageData' array, so we don't resolve anything here;
                    },
                    function (nextPageError) {
                      //If an error occurs, bail.
                      deferred.notify({
                        job_uuid: "n/a",
                        status: "error",
                        poll_count: 0,
                        page: currentPage,
                      });
                      deferred.reject(nextPageError);
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              } else {
                deferred.notify({
                  job_uuid: "n/a",
                  status: "success",
                  poll_count: 0,
                  page: currentPage,
                });
                deferred.resolve(pageData);
              }
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //info
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        getCustomerScore: function (customerId) {
          let deferred = $q.defer();

          const calculateAttributeScore = (attribute, weight) =>
            attribute ? weight : 0;

          CustomerScoreAPI.getScore(
            {
              dealer_id: UserService.dealer_id,
              customer_id: parseInt(customerId),
            },
            function (criteria) {
              let customerScore = 0;

              customerScore += calculateAttributeScore(
                criteria.customer_age,
                1
              );
              customerScore += calculateAttributeScore(criteria.app_enabled, 3);
              customerScore += calculateAttributeScore(criteria.app_users, 0.5);
              customerScore += calculateAttributeScore(
                criteria.video_enabled,
                1
              );
              customerScore += calculateAttributeScore(
                criteria.zwave_enabled,
                0.5
              );
              customerScore += calculateAttributeScore(
                criteria.door_enabled,
                0.5
              );
              customerScore += calculateAttributeScore(
                criteria.cell_enabled,
                3
              );
              customerScore += calculateAttributeScore(
                criteria.video_verification,
                0.5
              );

              criteria.score = customerScore;

              deferred.resolve(criteria);
            },
            function (error) {
              deferred.reject(error);
            }
          );

          return deferred.promise;
        },

        /**
         * Update the customer on the VK server
         */
        update: function () {
          var deferred = $q.defer();
          var _this = this;
          CustomersV2API.update(
            { customer_id: _this.id }, //params
            this.toJson(),
            function (data) {
              //success
              DashboardDataService.forceDashboardReload();
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              _this.extendMe(data.customer);
              deferred.resolve(data);
            },
            function (error) {
              //failure
              _this.handleError(error);
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        /**
         * Delete (destroy) the customer on the VK server
         */
        delete: function () {
          var deferred = $q.defer();
          var _this = this;
          let thirdPartyVideoDevicePromises = [];
          _this.getControlSystems(UserService.customer_id).then(
            function (systems) {
              for (let system of systems) {
                thirdPartyVideoDevicePromises.push(
                  ThirdPartyVideoService.getDevices(system.control_system.id)
                );
                if (
                  !systemIsSite(system.control_system) &&
                  systemWillBeAutoProgrammed(system.control_system) &&
                  systemIsScheduledForFutureInstall(system.control_system)
                ) {
                  SimManagementService.deactivate(
                    {
                      iccid: system.control_system.panels[0].sim_identifier,
                      key: UserService.dealerInfo.securecom_license,
                    },
                    system.control_system,
                    UserService.dealerInfo.dealer_code,
                    system.control_system.panels[0].id,
                    UserService.dealerInfo.id
                  ).then(
                    (success) => {},
                    (error) => {
                      deferred.notify({
                        job_uuid: "n/a",
                        status: "SimScheduleDeactivateError",
                        poll_count: 0,
                      });
                    }
                  );
                }
              }

              $q.all(thirdPartyVideoDevicePromises).then(
                function (customer) {
                  let deviceDeletePromises = [];

                  /*
                   * SCAPI will delete all the panels associated with the customer
                   * but NOT concepts related to systems. This loop attempts
                   * to remove lingering items associated with the customer's systems.
                   *
                   * NOTE: If this is exited early (user refreshes page, network loss, etc...)
                   * The actions CANNOT be recovered, there will be orphans,
                   * in the data related to customers / systems that no longer exist.
                   */

                  for (let system of customer) {
                    for (let device of system) {
                      if (device.device_type === "HIKVISION-DOORBELL") {
                        deviceDeletePromises.push(
                          ThirdPartyVideoService.hikvisionDoorbells.destroy(
                            device.id,
                            device.control_system_id
                          )
                        );
                      } else {
                        deviceDeletePromises.push(
                          ThirdPartyVideoService.destroyDevice(device.id)
                        );
                      }
                    }
                  }

                  $q.all(deviceDeletePromises).then(
                    function (success) {
                      CustomersV2API.destroy(
                        { customer_id: _this.id }, //params
                        function (data) {
                          //success
                          DashboardDataService.forceDashboardReload();
                          deferred.notify({
                            job_uuid: "n/a",
                            status: "success",
                            poll_count: 0,
                          });
                          deferred.resolve(data);
                        },
                        function (error) {
                          //failure
                          deferred.notify({
                            job_uuid: "n/a",
                            status: "error",
                            poll_count: 0,
                          });
                          deferred.reject(error);
                        }
                      ).catch(function (error) {
                        deferred.reject(error);
                      });
                    },
                    function (error) {
                      $rootScope.alerts.push({
                        type: "error",
                        text: "Error deleting linked Video devices on customer’s systems",
                      });
                      deferred.reject(error);
                    }
                  );
                },
                function (error) {
                  deferred.reject(error);
                }
              );
            },
            function (error) {}
          );
          return deferred.promise;
        },

        /**
         * Get a new, default customer template object from VK
         */
        new: function (dealerId) {
          var deferred = $q.defer();
          var _this = this;
          DealerCustomersV2API.new(
            { dealer_id: dealerId }, //params
            function (data) {
              //success
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              //Set default value for country to US for new systems
              data.customer.country = "US";
              _this.extendMe(data.customer);
              _this.isNew = true;
              // If the Dealer has the custom App option turned on, set the new user's access type to custom_app
              if (UserService.enabledCustomApp()) {
                _this.access_type = "custom";
              }
              deferred.resolve(data);
            },
            function (error) {
              //failure
              deferred.notify({
                job_uuid: "n/a",
                status: "error",
                poll_count: 0,
              });
              deferred.reject(error);
            },
            function (info) {
              //failure
              deferred.notify(info);
            }
          );
          return deferred.promise;
        },

        /**
         * Create the customer in VK, only after calling this.new()
         */
        create: function () {
          var deferred = $q.defer();
          var _this = this;
          DealerCustomersV2API.create(
            { dealer_id: _this.dealer_id }, //params
            this.toJson(),
            function (data) {
              //success
              DashboardDataService.forceDashboardReload();
              deferred.notify({
                job_uuid: "n/a",
                status: "success",
                poll_count: 0,
              });
              _this.extendMe(data.customer);
              deferred.resolve(data);
            },
            function (error) {
              //failure
              _this.handleError(error);
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },

        /**
         * Convert the object attributes into a suitable json format for updating to VK
         */
        toJson: function () {
          var json = {};
          json.customer = this;
          return json;
        },

        /**
         * Handle any errors
         * @param errorData
         */
        handleError: function (errorData) {
          if (angular.isUndefined(this.errors)) {
            this.errors = {};
          }
          angular.extend(this.errors, errorData.data.errors);
        },

        /**
         * Function to extend _this_ with additional/new attributes
         */
        extendMe: function (customerObject) {
          angular.extend(this, customerObject);
        },
      });

      // This 'constructor' allows you to create a new Customer object from either passing in a json object, or
      // by passing in a customerID.  If customerID is passed in, this Customer object will then hit the VK API to get the customer's data.
      if (typeof customerObject == "object") {
        this.extendMe(customerObject);
      } else {
        if (customerObject) {
          this.get(customerObject); // Get an existing customer
        } else {
          //this.dealer_id = dealerId;
          this.new(dealerId); // Get a new customer
        }
      }
    };
    return Customer;
  },
]);
