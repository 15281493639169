import { dangerouslyAddToApp as addAddSystemImageButton } from "components/AddSystemImageButton";
import { dangerouslyAddToApp as addAddToHomeScreenBanner } from "components/AddToHomeBanner";
import { dangerouslyAddToApp as addApAlarmVision } from "components/ApAlarmVision";
import { dangerouslyAddToApp as addAutoLogoutModal } from "components/AutoLogoutModal";
import { dangerouslyAddToApp as addSmallButton } from "components/Button";
import { dangerouslyAddToApp as addCamectBridgeTable } from "components/CamectBridgeTable";
import { dangerouslyAddToApp as addCameraRoot } from "components/CameraTable";
import { dangerouslyAddToApp as addCentralStationViewRoot } from "components/CentralStation";
import { dangerouslyAddToApp as addChangePasswordModal } from "components/ChangePasswordModal";
import { dangerouslyAddToApp as addCustomerReferralSettings } from "components/CustomerReferralSettings/";
import { dangerouslyAddToApp as addCustomerSummarySystemsTableComponent } from "components/CustomerSummarySystemTable";
import { dangerouslyAddToApp as addCustomFirmwareUploader } from "components/CustomFirmwareUploader";
import { dangerouslyAddToApp as addCustomRolesRoot } from "components/CustomRoles";
import { dangerouslyAddToApp as addDownloadsPage } from "components/Downloads";
import { dangerouslyAddToApp as addFullProgramming } from "components/FullProgramming";
import { dangerouslyAddToApp as addLoginFormDisplay } from "components/LoginForm";
import { dangerouslyAddToApp as addPrivacyPolicy } from "components/LoginForm/PrivacyPolicy";
import { dangerouslyAddToApp as addTermsAndConditions } from "components/LoginForm/TermsAndConditions";
import { dangerouslyAddToApp as addManagePassword } from "components/ManagePasswordUpdates";
import { dangerouslyAddToApp as addMarketingCentralDisplay } from "components/MarketingCentral";
import { dangerouslyAddToApp as addMobileCameraComponent } from "components/MobileCamera/index";
import { dangerouslyAddToApp as addPageHeader } from "components/PageHeader";
import { dangerouslyAddToApp as addEditCamera } from "components/RecorderCameraEdit";
import { dangerouslyAddToApp as addEditRecorder } from "components/RecorderEdit";
import { dangerouslyAddToApp as addRecorderTable } from "components/RecorderTable";
import { dangerouslyAddToApp as addSendAllUserCodesButton } from "components/SendAllUserCodesButton";
import { dangerouslyAddToApp as addSevenInchKeypadSettings } from "components/SevenInchKeypadSettings";
import { dangerouslyAddToApp as addSiteForm } from "components/SiteForm";
import { dangerouslyAddToApp as addStatusBadge } from "components/StatusBadge";
import { dangerouslyAddToApp as addTagEdit } from "components/TagEdit";
import { dangerouslyAddToApp as addTagField } from "components/TagField";
import { dangerouslyAddToApp as addTagsSettings } from "components/TagsSettings";
import { dangerouslyAddToApp as addTakeoverPanelSetup } from "components/TakeoverPanelSetup";
import { dangerouslyAddToApp as addToasts } from "components/Toasts/ToastsAngularEntryPoint";
import { dangerouslyAddToApp as addTwoFaRoot } from "components/TwoFA";
import { dangerouslyAddToApp as addUniviewCameraEdit } from "components/UniviewCameraEdit";
import { dangerouslyAddToApp as addVarVV } from "components/VarVV";
import { dangerouslyAddToApp as addXcCameraEdit } from "components/XcCameraEdit";
import { dangerouslyAddToApp as addDealerActivityPage } from "pages/DealerActivity";
import { dangerouslyAddToApp as addEulaAgreementRedirectPageRoot } from "pages/EulaAgreementRedirectPage";
import { dangerouslyAddToApp as addGroupEdit } from "pages/Groups/GroupEditAngularEntryPoint";
import { dangerouslyAddToApp as addGroupNew } from "pages/Groups/GroupNewAngularEntryPoint";
import { dangerouslyAddToApp as addGroups } from "pages/Groups/GroupsAngularEntryPoint";
import { dangerouslyAddToApp as addTemplatesPage } from "pages/Templates";
import { dangerouslyAddToApp as addTemplatesList } from "pages/TemplatesList";

addSiteForm();
addSevenInchKeypadSettings();
addSmallButton();
addPageHeader();
addTakeoverPanelSetup();
addToasts();
addCustomerReferralSettings();
addFullProgramming();
addMarketingCentralDisplay();
addCentralStationViewRoot();
addCustomRolesRoot();
addStatusBadge();
addDownloadsPage();
addTwoFaRoot();
addChangePasswordModal();
addAutoLogoutModal();
addLoginFormDisplay();
addManagePassword();
addCamectBridgeTable();
addCameraRoot();
addCustomFirmwareUploader();
addRecorderTable();
addApAlarmVision();
addXcCameraEdit();
addEditRecorder();
addEditCamera();
addUniviewCameraEdit();
addVarVV();
addTemplatesPage();
addTemplatesList();
addDealerActivityPage();
addTagEdit();
addTagField();
addTagsSettings();
addMobileCameraComponent();
addAddToHomeScreenBanner();
addAddSystemImageButton();
addCustomerSummarySystemsTableComponent();
addSendAllUserCodesButton();
addTermsAndConditions();
addPrivacyPolicy();
addEulaAgreementRedirectPageRoot();
addGroups();
addGroupEdit();
addGroupNew();
