/**
 * @generated SignedSource<<f806a705192ed972b09f437a5f7e518a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type XT75CommPathFields_communicationPath$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommPathAlarmReportsField_communicationPath" | "CommPathCellApnField_communicationPath" | "CommPathCheckInMinutesField_communicationPath" | "CommPathCommTypeField_communicationPath" | "CommPathContext_communicationPath" | "CommPathDoorAccessReportsField_communicationPath" | "CommPathEncryptionField_communicationPath" | "CommPathFailTestHoursField_communicationPath" | "CommPathFailTimeField_communicationPath" | "CommPathNumberField_communicationPath" | "CommPathOpenCloseUserReportsField_communicationPath" | "CommPathReceiverIpAddressField_communicationPath" | "CommPathReceiverPortField_communicationPath" | "CommPathRetryTimeField_communicationPath" | "CommPathSendFailField_communicationPath" | "CommPathSendPathField_communicationPath" | "CommPathSupervisoryTroubleReportsField_communicationPath" | "CommPathTestFrequencyDayField_communicationPath" | "CommPathTestFrequencyNumberField_communicationPath" | "CommPathTestFrequencyUnitField_communicationPath" | "CommPathTestReportField_communicationPath" | "CommPathTestTimeField_communicationPath" | "CommPathTypeField_communicationPath" | "CommPathUseCheckInField_communicationPath">;
  readonly " $fragmentType": "XT75CommPathFields_communicationPath";
};
export type XT75CommPathFields_communicationPath$key = {
  readonly " $data"?: XT75CommPathFields_communicationPath$data;
  readonly " $fragmentSpreads": FragmentRefs<"XT75CommPathFields_communicationPath">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XT75CommPathFields_communicationPath",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathContext_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathNumberField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathCommTypeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTypeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestReportField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestFrequencyNumberField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestFrequencyUnitField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestFrequencyDayField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathTestTimeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathUseCheckInField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathCheckInMinutesField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathFailTimeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathReceiverIpAddressField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathReceiverPortField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathEncryptionField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathFailTestHoursField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathCellApnField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathRetryTimeField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathAlarmReportsField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSupervisoryTroubleReportsField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathOpenCloseUserReportsField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathDoorAccessReportsField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSendFailField_communicationPath"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommPathSendPathField_communicationPath"
    }
  ],
  "type": "CommunicationPath",
  "abstractKey": null
};

(node as any).hash = "c8abcd28fcea5aa7b2f5efcf4786ae2f";

export default node;
